import axios from 'axios';
import logger from 'src/logger';

export const checkRenewalStatusByOrderIdPaymentId = async (orderId, paymentId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/renewal/check-renewal?orderId=${orderId}&paymentId=${paymentId}`
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    return response;
  } catch (error) {
    logger('check renewal ERROR : ', error);
    throw error.message;
  }
};

export const mandateRevoke = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/renewal/mandate-revoke`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== '200') throw response;
    return response;
  } catch (error) {
    logger('mandate revoke Error : ', error);
    throw error.message;
  }
};
