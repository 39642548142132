/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  InputLabel,
  Select,
  Stack,
  MenuItem
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import { fetchPlatformsApi } from 'src/services/platform/platform';
import { fetchProductMasterData } from 'src/services/product/product';
import { fetchPaymentMasterApi } from 'src/services/payment/paymentMaster';
import { getPlanMasterApi } from 'src/services/plans/planMaster';
import {
  addNewPaymentSequenceData,
  updatePaymentSequenceApi,
  fetchPaymentSequenceApi,
  fetchSinglePaymentSequenceData
} from 'src/services/payment/paymentSequence';

const PaymentSequenceForm = () => {
  const { paymentSeqId } = useParams();
  const navigate = useNavigate();
  const [payment, setPayment] = useState([]);
  const [plan, setPlan] = useState([]);
  const [product, setProduct] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [editPaymentSeqDetails, setEditPaymentSeqDetails] = useState({});
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });

  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };
  // Validates plan schema and returns validation schema. This is used to validate plans before they are created
  const validationSchema = yup.object({
    PRODUCT_ID: yup.number().required('Product id is required'),
    PLAN_ID: yup.number().required('Plan id is required'),
    PAYMENT_ID: yup.number().required('Payment id is required'),
    DISPLAY_ORDER: yup.number().required('Display Order is required'),
    PLATFORM_ID: yup.number().required('Platform id is required')
    // STATUS: yup.boolean().required('Status is required'),
  });
  const formik = useFormik({
    initialValues: {
      PRODUCT_ID: editPaymentSeqDetails['PRODUCT_ID']
        ? editPaymentSeqDetails['PRODUCT_ID']
        : '',
      PLAN_ID: editPaymentSeqDetails['PLAN_ID'] ? editPaymentSeqDetails['PLAN_ID'] : '',
      PAYMENT_ID: editPaymentSeqDetails['PAYMENT_ID']
        ? editPaymentSeqDetails['PAYMENT_ID']
        : '',
      DISPLAY_ORDER: editPaymentSeqDetails['DISPLAY_ORDER']
        ? editPaymentSeqDetails['DISPLAY_ORDER']
        : '',
      STATUS: editPaymentSeqDetails['STATUS']?.toString()
        ? editPaymentSeqDetails['STATUS']?.toString()
        : '0',
      PLATFORM_ID: editPaymentSeqDetails['PLATFORM_ID']
        ? editPaymentSeqDetails['PLATFORM_ID']
        : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // let addedON = Math.floor(Date.now() / 1000);
      // let addedBy = 1;
      const obj = {
        ...values,
        PAYMENT_SEQ_ID: parseInt(paymentSeqId) ? parseInt(paymentSeqId) : '',
        PRODUCT_ID: parseInt(values['PRODUCT_ID']),
        PLAN_ID: parseInt(values['PLAN_ID']),
        PAYMENT_ID: parseInt(values['PAYMENT_ID']),
        DISPLAY_ORDER: parseInt(values['DISPLAY_ORDER']),
        STATUS: parseInt(values['STATUS']),
        PLATFORM_ID: parseInt(values['PLATFORM_ID'])
      };
      if (paymentSeqId) {
        updatePaymentSequenceApi({ ...obj })
          .then((res) => {
            console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addNewPaymentSequenceData({ ...obj })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }
    }
  });

  useEffect(() => {
    if (paymentSeqId) {
      fetchSinglePaymentSequenceData(paymentSeqId)
        .then((res) => {
          // console.log(res);
          setEditPaymentSeqDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/payment/sequence');
        });
    }
    //payment
    fetchPaymentMasterApi(1, 10, true)
      .then((res) => {
        setPayment(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //plan
    getPlanMasterApi(1, 10, true)
      .then((res) => {
        setPlan(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //platform
    fetchPlatformsApi(1, 10, true)
      .then((res) => {
        setPlatform(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //product
    fetchProductMasterData(1, 10, true)
      .then((res) => {
        setProduct(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {paymentSeqId ? 'Edit' : 'Add New'} Payment Sequence
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched?.PRODUCT_ID && formik.errors?.PRODUCT_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
                Select Product
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Select Product"
                name="PRODUCT_ID"
                value={formik.values?.PRODUCT_ID}
                onChange={formik.handleChange}
              >
                {product?.map((el, i) => (
                  <MenuItem value={el?.PRODUCT_ID}>{el?.PRODUCT_NAME}</MenuItem>
                ))}
                {!product && <MenuItem value={''}>--No Products--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched?.PRODUCT_ID && formik.errors?.PRODUCT_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PLAN_ID && formik.errors.PLAN_ID}
            >
              <InputLabel id="demo-simple-select-required-label">Select Plan</InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Plan *"
                name="PLAN_ID"
                value={formik.values.PLAN_ID}
                onChange={formik.handleChange}
              >
                {plan?.map((el, i) => (
                  <MenuItem value={el?.PLAN_ID}>{el?.PLAN_NAME}</MenuItem>
                ))}
                {!plan && <MenuItem value={''}>--No Plans--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PLAN_ID && formik.errors.PLAN_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PLAN_ID && formik.errors.PLAN_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
                Select Payment
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Payment *"
                name="PAYMENT_ID"
                value={formik.values.PAYMENT_ID}
                onChange={formik.handleChange}
              >
                {payment?.map((el, i) => (
                  <MenuItem value={el?.PAYMENT_ID}>{el?.PAYMENT_NAME}</MenuItem>
                ))}
                {!payment && <MenuItem value={''}>--No Payments--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="DISPLAY_ORDER"
              style={{ width: '100%', marginBottom: '10px' }}
              name="DISPLAY_ORDER"
              value={formik.values.DISPLAY_ORDER}
              onChange={formik.handleChange}
              error={formik.touched.DISPLAY_ORDER && Boolean(formik.errors.DISPLAY_ORDER)}
              helperText={formik.touched.DISPLAY_ORDER && formik.errors.DISPLAY_ORDER}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              component="fieldset"
              error={formik.touched.STATUS && Boolean(formik.errors.STATUS)}
            >
              <FormLabel component="legend">Status</FormLabel>

              <RadioGroup
                name="STATUS"
                value={formik.values.STATUS}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="1" control={<Radio />} label="Active" />
                  <FormControlLabel value="0" control={<Radio />} label="De-Active" />
                </div>
              </RadioGroup>
              <FormHelperText>
                {formik.touched.STATUS && formik.errors.STATUS}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
                Select Platform
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Platform *"
                name="PLATFORM_ID"
                value={formik.values.PLATFORM_ID}
                onChange={formik.handleChange}
              >
                {platform?.map((el, i) => (
                  <MenuItem value={el?.PLATFORM_ID}>{el?.PLATFORM_NAME}</MenuItem>
                ))}
                {!plan && <MenuItem value={''}>--No Platforms--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/payment/sequence');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
export default PaymentSequenceForm;
