import React from 'react';
import PlanSequenceForm from 'src/components/plans/sequence/PlanSequenceForm';

const AddEditPlanSequence = () => (
  <div>
    <PlanSequenceForm />
  </div>
);

export default AddEditPlanSequence;
