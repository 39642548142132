import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const fetchPlatformsApi = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/platform?page=${page}&view=${limit}&getAll=${getAll}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;
    logger('fetchPlatform', response);
    // console.log("data",response);
    const dataTable = response?.data.platform_master ? response.data.platform_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;
    return data;
  } catch (error) {
    logger('fetchPlatformERROR : ', error);
    throw error.message;
  }
};

export const fetchPlatformSearchApi = async (page, limit, input) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/platform/search?page=${page}&view=${limit}&input=${input}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('searchPlatform', response);
    const dataTable = response?.data?.platform_master
      ? response.data.platform_master
      : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('platformSearchERROR : ', error);
    throw error.message;
  }
};

export const fetchSinglePlatformApi = async (platformId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/platform/${platformId}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('fetchSinglePlatform', response);
    return response;
  } catch (error) {
    logger('fetchSinglePlatformERROR : ', error);
    throw error.message;
  }
};

export const addPlatformApi = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/platform`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('add platform', response);
    return response;
  } catch (error) {
    logger('add platform Error : ', error);
    throw error.message;
  }
};

export const updatePlatformApi = async ({
  PLATFORM_ID,
  PLATFORM_CODE,
  PLATFORM_NAME,
  USER_ID
}) => {
  try {
    const getData = await axios.put(`${process.env.REACT_APP_API_URL}/platform/update`, {
      PLATFORM_ID,
      PLATFORM_CODE,
      PLATFORM_NAME,
      USER_ID
    });
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('updateProduct', response);
    return response;
  } catch (error) {
    // logger('updateProductERROR : ', error);
    throw error.message;
  }
};
export const removePlatformByIdApi = async (id) => {
  try {
    const getData = await axios.delete(`${process.env.REACT_APP_API_URL}/platform/${id}`);
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.platform_master
      ? response.data.platform_master
      : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('remProductERROR : ', error);
    throw error.message;
  }
};

export const customData = '';
