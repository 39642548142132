/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  Typography
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import CustomNotifier from 'src/customComponents/Notifier';
import {
  addNewPaymentMasterData,
  fetchSinglePaymentMasterData,
  updatePaymentMasterApi
} from 'src/services/payment/paymentMaster';

// const FormData = require('form-data');

const validationSchema = yup.object({
  PAYMENT_NAME: yup.string().required('Payment Name is required'),
  PAYMENT_TYPE: yup.string().required('Payment Type is required'),
  PAYMENT_DESC: yup.string().required('Payment Type is required'),
  PAYMENT_LOGO: yup.string().required('Payment Logo is required'),
  IS_RENEWAL_AVAILABLE: yup.string().required('Is Renewal Available Code, is required')
  // STATUS: yup.boolean().required('Status is required'),
});

const PaymentMasterForm = () => {
  const navigate = useNavigate();
  const { payMastId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });

  const [editPayMastDetails, setEditPayMastDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const formik = useFormik({
    initialValues: {
      PAYMENT_NAME: editPayMastDetails['PAYMENT_NAME']?.toString()
        ? editPayMastDetails['PAYMENT_NAME']?.toString()
        : '',
      PAYMENT_TYPE: editPayMastDetails['PAYMENT_TYPE']?.toString()
        ? editPayMastDetails['PAYMENT_TYPE']?.toString()
        : '',
      PAYMENT_DESC: editPayMastDetails['PAYMENT_DESC']?.toString()
        ? editPayMastDetails['PAYMENT_DESC']?.toString()
        : '',
      PAYMENT_LOGO: editPayMastDetails['PAYMENT_LOGO']?.toString()
        ? editPayMastDetails['PAYMENT_LOGO']?.toString()
        : '',

      IS_RENEWAL_AVAILABLE: editPayMastDetails['IS_RENEWAL_AVAILABLE']?.toString()
        ? editPayMastDetails['IS_RENEWAL_AVAILABLE']?.toString()
        : '0',
      STATUS: editPayMastDetails['STATUS']?.toString()
        ? editPayMastDetails['STATUS']?.toString()
        : '0'
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        PAYMENT_MAST_ID: payMastId ? payMastId : '',
        IS_RENEWAL_AVAILABLE: parseInt(value['IS_RENEWAL_AVAILABLE']),
        STATUS: parseInt(value['STATUS'])
        // LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };
      if (payMastId) {
        updatePaymentMasterApi({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addNewPaymentMasterData({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }
    }
  });

  useEffect(() => {
    if (payMastId) {
      fetchSinglePaymentMasterData(payMastId)
        .then((res) => {
          setEditPayMastDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/payment/master');
        });
    }
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {payMastId ? 'Edit' : 'Add New'} Payment Master
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_NAME"
              value={formik.values.PAYMENT_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_NAME && Boolean(formik.errors.PAYMENT_NAME)}
              helperText={formik.touched.PAYMENT_NAME && formik.errors.PAYMENT_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_TYPE"
              value={formik.values.PAYMENT_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_TYPE && Boolean(formik.errors.PAYMENT_TYPE)}
              helperText={formik.touched.PAYMENT_TYPE && formik.errors.PAYMENT_TYPE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Description"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_DESC"
              value={formik.values.PAYMENT_DESC}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_DESC && Boolean(formik.errors.PAYMENT_DESC)}
              helperText={formik.touched.PAYMENT_DESC && formik.errors.PAYMENT_DESC}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Logo"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_LOGO"
              value={formik.values.PAYMENT_LOGO}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_LOGO && Boolean(formik.errors.PAYMENT_LOGO)}
              helperText={formik.touched.PAYMENT_LOGO && formik.errors.PAYMENT_LOGO}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend"> Is Renewal Available ?</FormLabel>
              <RadioGroup
                row
                aria-label="IS_RENEWAL_AVAILABLE"
                name="IS_RENEWAL_AVAILABLE"
                value={formik.values.IS_RENEWAL_AVAILABLE}
                onChange={formik.handleChange}
              >
                <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                <FormControlLabel value={'0'} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              component="fieldset"
              error={formik.touched.STATUS && Boolean(formik.errors.STATUS)}
            >
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                name="STATUS"
                value={formik.values.STATUS}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="1" control={<Radio />} label="Active" />
                  <FormControlLabel value="0" control={<Radio />} label="De-Active" />
                </div>
              </RadioGroup>
              <FormHelperText>
                {formik.touched.STATUS && formik.errors.STATUS}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/payment/mapping');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default PaymentMasterForm;
