import React from 'react';
import PlatformMasterForm from 'src/components/platfrom/PlatformMasterForm';

const AddEditProductMaster = () => (
  <div>
    <PlatformMasterForm />
  </div>
);

export default AddEditProductMaster;
