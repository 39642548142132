/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  register
} from 'src/services/auth';
import CustomNotifier from 'src/customComponents/Notifier';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  USERNAME: yup.string().required('Username Name is required'),
  PASSWORD: yup.string().required('Password is required'),
  FULL_NAME: yup.string('should be string').required('Full Name is required'),
  USER_TYPE: yup.string().required('User Type is required'),
  });

const RegisterForm = () => {
  const { planMastId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [showPassword, setShowPassword] = useState(false);
  // const [editPlanDetails, setEditPlanDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const formik = useFormik({
    initialValues: {
      USERNAME: '',
      PASSWORD: '',
      FULL_NAME: '',
      USER_TYPE: ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      const obj = {
        ...value,
      };
      if (obj) {
        register(obj.USERNAME, obj.PASSWORD, obj.FULL_NAME, obj.USER_TYPE)
          .then((res) => {
            console.log('in register form', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }
    }
  });

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        Register User
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Username"
              style={{ width: '100%', marginBottom: '10px' }}
              name="USERNAME"
              value={formik.values.USERNAME}
              onChange={formik.handleChange}
              error={formik.touched.USERNAME && Boolean(formik.errors.USERNAME)}
              helperText={formik.touched.USERNAME && formik.errors.USERNAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              id="outlined-disabled"
              label="Password"
              type="password"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PASSWORD"
              value={formik.values.PASSWORD}
              onChange={formik.handleChange}
              error={formik.touched.PASSWORD && Boolean(formik.errors.PASSWORD)}
              helperText={formik.touched.PASSWORD && formik.errors.PASSWORD}
            /> */}
            <TextField
              id="outlined-disabled"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              style={{ width: '100%', marginBottom: '10px' }}
              name="PASSWORD"
              value={formik.values.PASSWORD}
              onChange={formik.handleChange}
              error={formik.touched.PASSWORD && Boolean(formik.errors.PASSWORD)}
              helperText={formik.touched.PASSWORD && formik.errors.PASSWORD}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Full Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="FULL_NAME"
              value={formik.values.FULL_NAME}
              onChange={formik.handleChange}
              error={formik.touched.FULL_NAME && Boolean(formik.errors.FULL_NAME)}
              helperText={formik.touched.FULL_NAME && formik.errors.FULL_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              label="User Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="USER_TYPE"
              value={formik.values.USER_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.USER_TYPE && Boolean(formik.errors.USER_TYPE)}
              helperText={formik.touched.USER_TYPE && formik.errors.USER_TYPE}
            /> */}
            <FormControl fullWidth>
          <InputLabel id="demo-simple-select-required-label">
            Select User Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            name="USER_TYPE"
            value={formik.values.USER_TYPE}
            onChange={formik.handleChange}
            label="Select User Type"
          >
            <MenuItem value={'A'}>Admin</MenuItem>
            <MenuItem value={'S'}>Senior</MenuItem>
          </Select>
        </FormControl>
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/admin_user/register');
            }}
          >
            Cancel
          </Button> */}
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default RegisterForm;
