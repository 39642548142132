import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormLabel
} from '@material-ui/core';
import { useFormik } from 'formik';

const BillingReportsForm = () => {
  const formik = useFormik({
    initialValues: {
      HungamaMus: '',
      MobileWallets: '',
      Patym: '',
      StartDate: '',
      EndDate: ''
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('string', JSON.stringify(values));
    },
  });
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      {/* <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} /> */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Hungama Mus
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Hungama Mus"
                name="HungamaMus"
                value={formik.values.HungamaMus}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={2} sm={4} md={4}>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Mobile Wallets
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Mobile Wallets"
                name="MobileWallets"
                value={formik.values.MobileWallets}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Patym
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Patym"
                name="Patym"
                value={formik.values.Patym}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            {/* <DateRangePicker
              startText="Check-in"
             endText="Check-out"
             value={value}
             onChange={(newValue) => setValue(newValue)}
             renderInput={(startProps, endProps) => (
             <React.Fragment>
             <TextField {...startProps} />
             <DateRangeDelimiter> to </DateRangeDelimiter>
             <TextField {...endProps} />
             </React.Fragment>
      )}
    /> */}
            <FormControl fullWidth>
              <FormLabel component="legend">Start Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                label=""
                name="StartDate"
                type="date"
                // defaultValue="Plan Country"
                value={formik.values.StartDate}
                onChange={formik.handleChange}
                error={formik.touched.StartDate && Boolean(formik.errors.StartDate)}
                helperText={formik.touched.StartDate && formik.errors.StartDate}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <FormLabel component="legend">End Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                label=""
                name="EndDate"
                type="date"
                // defaultValue="Plan Country"
                value={formik.values.EndDate}
                onChange={formik.handleChange}
                error={formik.touched.EndDate && Boolean(formik.errors.EndDate)}
                helperText={formik.touched.EndDate && formik.errors.EndDate}

              />
            </FormControl>
          </Grid>
        </Grid>
        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <div style={{ textAlign: 'right' }}>

          <Button type="submit">
            Go
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default BillingReportsForm;
