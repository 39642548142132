/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import DatePicker from '@mui/lab/DatePicker';
import { vendorReportDownload } from 'src/services/report/vendorReportApis';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  vendor: yup.string().required('Vendor is required'),
  start_date: yup.string().required('Start Date is required'),
  end_date: yup.string().required('End Date is required')
});

const VendorReportForm = () => {
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      vendor: '',
      start_date: '',
      end_date: ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      // let addedON = Math.floor(Date.now() / 1000);
      // let addedBy = 1;
      const obj = {
        ...value,
        start_date: dayjs(value.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(value.end_date).format('YYYY-MM-DD')
      };

      console.log('values:::', obj);

      vendorReportDownload({ ...obj })
        .then((res) => {
          console.log('res ::', res);
          setSnackBarOpen(true, 'Report Downloaded.');
        })
        .catch((error) => {
          setSnackBarOpen(true, error);
        });
    }
  });

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {'Vendor Report'}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={formik.touched.vendor && formik.errors.vendor}>
              {/* <InputLabel id="demo-simple-select-required-label">
                Select Vendor
              </InputLabel> */}
              <FormLabel component="legend">Select Vendor</FormLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                name="vendor"
                value={formik.values.vendor}
                onChange={formik.handleChange}
              >
                <MenuItem value={'meghbela'}>{'Meghbela'}</MenuItem>
                <MenuItem value={'ani'}>{'ANI'}</MenuItem>
                <MenuItem value={'tatasky'}>{'Tatasky'}</MenuItem>
                <MenuItem value={'playbox'}>{'Playbox'}</MenuItem>
                {/* {vendor?.map((el, i) => (
                  <MenuItem value={el?.vendor_id}>{el?.vendor_name}</MenuItem>
                ))}
                {!vendor && <MenuItem value={''}>--No Vendors--</MenuItem>} */}
              </Select>
              <FormHelperText>
                {formik.touched.vendor && formik.errors.vendor}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">Start Date</FormLabel>
            <TextField
              label=""
              style={{ width: '100%', marginBottom: '10px' }}
              name="start_date"
              type="date"
              value={formik.values.start_date}
              onChange={formik.handleChange}
              error={formik.touched.start_date && Boolean(formik.errors.start_date)}
              helperText={formik.touched.start_date && formik.errors.start_date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">Start Date</FormLabel>
            <TextField
              label=""
              style={{ width: '100%', marginBottom: '10px' }}
              name="end_date"
              type="date"
              value={formik.values.end_date}
              onChange={formik.handleChange}
              error={formik.touched.end_date && Boolean(formik.errors.end_date)}
              helperText={formik.touched.end_date && formik.errors.end_date}
            />
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/app/dashboard');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Download
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default VendorReportForm;
