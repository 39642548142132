import axios from 'axios';

/* RabbitMQ Api */
export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
  'api-key': process.env.REACT_APP_API_KEY
};
export default async function rabbitMQReceiverApi(body) {
  const mqBody = {
    ...body,
    event_properties: { ...body?.event_properties },
    user_properties: { ...body?.user_properties },
    event_type: body?.event_type,
    extra: 'PayAdminDashboardEvent'
  };
  const newMQBody = {
    ...mqBody,
    'auth-key': process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME
  };
  if (newMQBody) {
    axios({
      method: 'POST',
      url: 'https://payapi.hungama.com/v1/notify/mq/receiver',
      headers,
      data: newMQBody
    })
      .then((res) => {
        // console.log(res);
        if (res.status >= 200 && res.status <= 299) {
          return { status: 'SUCCESS', data: res.data };
        }
        throw new Error(res);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        throw new Error(err);
      });
  }
}
