import {
  Box,
  // Button,
  Card,
  CardContent,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
// import { Search as SearchIcon } from 'react-feather';

const OrderSearchTools = ({ filteredData, ...props }) => {
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInput(newValue);
    // filteredData(value);
  };

  // const handleClick = () => {
  //   alert('onclick');
  //   // filteredData(inputVar);
  // };

  return (
    <Box {...props}>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button color="inherit">Import</Button>
        <Button color="inherit" sx={{ mx: 1 }}>
          Export
        </Button>
        <Button color="inherit" variant="contained">
          Add customer
        </Button>
      </Box> */}
      <Box sx={{ mt: 1 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500, display: 'flex' }}>
              <TextField
                fullWidth
                onChange={(e) => handleChange(e, e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) filteredData(input);
                }}
                value={input}
                placeholder="Enter Identity, Order Id Or Username"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        filteredData(input);
                      }}
                    >
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

OrderSearchTools.propTypes = {
  filteredData: PropTypes.func
};

export default OrderSearchTools;
