import React from 'react';
import {
  Container,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';

const ManageRightsForm = () => (
  <Container style={{ marginTop: '1rem' }}>

    <h3>Manage Product Rights</h3>
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >

      <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-required-label">
            Select User
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
                  //   value={age}
            label="Select User"
                  //   onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-required-label">
            Select Product
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
                  //   value={age}
            label="Select Product"
                  //   onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 120, maxWidth: 140, margin: 1 }}>
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </Box>
    </Paper>
  </Container>
);

export default ManageRightsForm;
