/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogBox({
  isOpen,
  dialogTitle,
  dialogMsg,
  dialogBtnName,
  showDialogBtn,
  dialogComponent: DialogComponent,
  dialogSubmitMethod,
  dialogClearMethod
}) {
  const [open, setOpen] = React.useState(isOpen ? isOpen : false);

  const handleClose = () => {
    setOpen(false);
  };

  console.log(
    'isOpen',
    isOpen,
    'dialogTitle',
    dialogTitle,
    'dialogMsg',
    dialogMsg,
    'dialogBtnName',
    dialogBtnName,
    'showDialogBtn',
    showDialogBtn,
    'DialogComponent',
    DialogComponent,
    'DialogSubmitMethod',
    dialogSubmitMethod,
    'dialogClearMethod',
    dialogClearMethod
  );

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <>
            {dialogMsg && <DialogContentText>{dialogMsg}</DialogContentText>}
            {DialogComponent && (
              <div>
                <DialogComponent />
              </div>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClearMethod} color="primary">
            Cancel
          </Button>
          {showDialogBtn && (
            <Button
              onClick={(e) => {
                if (typeof dialogSubmitMethod === 'function') {
                  dialogSubmitMethod();
                  dialogClearMethod();
                }
              }}
              color="primary"
            >
              {dialogBtnName}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
