/* eslint-disable indent */
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dashboard } from '@material-ui/icons';
import { Button } from '@material-ui/core';

const Route = ({ href, title }) => {
  const location = useLocation();

  const active = href ? matchPath({ path: href, end: true }, location.pathname) : false;

  return (
    <Button
      startIcon={<Dashboard />}
      component={RouterLink}
      sx={{
        color: 'text.secondary',
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        py: 1.25,
        textTransform: 'none',
        width: '100%',
        ...(active && {
          color: 'primary.main'
        }),
        '& svg': {
          mr: 1
        }
      }}
      to={href}
    >
      <span>{title}</span>
    </Button>
  );
};

Route.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string
};

export default Route;
