/* eslint-disable */
import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const vendorReportDownload = async (payload) => {
  let { vendor, start_date, end_date } = payload;
  try {
    // const getData = await axios.get(
    //   `https://pac.hungama.com/reports/ani_playbox_tatasky.php?reportType=${vendor}&startDate=${start_date}&endDate=${end_date}`
    // );
    // const response = getData?.data;
    // if (response.statusCode !== 200) throw response;
    // logger('vendor response:', response);

    // return response;

    window.open(
      `https://pac.hungama.com/reports/ani_playbox_tatasky.php?reportType=${vendor}&startDate=${start_date}&endDate=${end_date}`,
      '_blank'
    );
  } catch (error) {
    logger('vendorReportErr : ', error);
    throw error.message;
  }
};

export const orderReportDownload = async (payload) => {
  let { start_date, end_date } = payload;
  try {
    const newWindow = window.open(
       `${process.env.REACT_APP_API_URL}/order/download?startDate=${start_date}&endDate=${end_date}`,
      '_blank'
    );
    // newWindow.focus(); // Focus on the new window
    // newWindow.blur(); // Remove focus from the new window, effectively hiding it
  } catch (error) {
    logger('vendorReportErr : ', error);
    throw error.message;
  }
};

export const orderMisReport1Download = async (payload) => {
  let { start_date, end_date } = payload;
  try {
    const newWindow = window.open(
       `${process.env.REACT_APP_API_URL}/order/misOrder/download1?startDate=${start_date}&endDate=${end_date}`,
      '_blank'
    );
    // newWindow.focus(); // Focus on the new window
    // newWindow.blur(); // Remove focus from the new window, effectively hiding it
  } catch (error) {
    logger('orderReport1Err : ', error);
    throw error.message;
  }
};

export const orderMisReport2Download = async (payload) => {
  let { start_date, end_date } = payload;
  try {
    const newWindow = window.open(
       `${process.env.REACT_APP_API_URL}/order/misOrder/download2?startDate=${start_date}&endDate=${end_date}`,
      '_blank'
    );
    // newWindow.focus(); // Focus on the new window
    // newWindow.blur(); // Remove focus from the new window, effectively hiding it
  } catch (error) {
    logger('orderReport2Err : ', error);
    throw error.message;
  }
};

export const orderMisReport3Download = async (payload) => {
  let { start_date, end_date } = payload;
  try {
    const newWindow = window.open(
       `${process.env.REACT_APP_API_URL}/order/misOrder/download3?startDate=${start_date}&endDate=${end_date}`,
      '_blank'
    );
    // newWindow.focus(); // Focus on the new window
    newWindow.blur(); // Remove focus from the new window, effectively hiding it
  } catch (error) {
    logger('orderReport3Err : ', error);
    throw error.message;
  }
};