import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core';
import { StyledTableCell } from '../../common/tableStyles';

const UserListingList = () => (
  <div>
    <Card style={{ margin: '20px' }}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>email</StyledTableCell>
                <StyledTableCell>Product Name</StyledTableCell>
                <StyledTableCell>User Type</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>1</StyledTableCell>
                <StyledTableCell>2</StyledTableCell>
                <StyledTableCell>3</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  </div>
);

export default UserListingList;
