import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
// import {  } from '@material-ui/icons';
import { Button, List, Collapse } from '@material-ui/core';

const NestedRoutes = ({ title, nestedRoutes, active }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <List
        sx={{
          bgcolor: 'background.paper',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main'
          })
        }}
        style={{ marginTop: '-0.5rem', marginBottom: '-0.5rem' }}
      >
        <Button
          onClick={handleClick}
          component={RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%'
          }}
          to="#"
        >
          <span>{title}</span>
          {open ? (
            <ExpandLess
              sx={{
                color: 'primary.main'
              }}
              style={{ marginLeft: '0' }}
            />
          ) : (
            <ExpandMore style={{ marginLeft: '0' }} />
          )}
        </Button>
        {nestedRoutes.map((item) => (
          <Collapse
            key={item.id}
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: '0.5rem' }}
          >
            <List component="div" disablePadding>
              <Button
                component={RouterLink}
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'medium',
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  py: 1.25,
                  textTransform: 'none',
                  width: '100%',
                  ...(active && {
                    color: 'primary.main'
                  }),
                  '& svg': {
                    mr: 1
                  }
                }}
                to={item.href}
              >
                <span>{item.title}</span>
              </Button>
            </List>
          </Collapse>
        ))}
      </List>
    </>
  );
};

NestedRoutes.propTypes = {
  title: PropTypes.string,
  nestedRoutes: PropTypes.array,
  active: PropTypes.bool
};

export default NestedRoutes;
