import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const fetchProductMasterData = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/product?page=${page}&view=${limit}&getAll=${getAll}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;
    logger('addProduct', response);
    // console.log("data",response);
    const dataTable = response?.data.product_master ? response.data.product_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;
    return data;
  } catch (error) {
    logger('addProductERROR : ', error);
    throw error.message;
  }
};

export const fetchSearchData = async (page, limit, input) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/search?page=${page}&view=${limit}&input=${input}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('MasterProduct', response);
    const dataTable = response?.data?.product_master ? response.data.product_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('productMasterSearchERROR : ', error);
    throw error.message;
  }
};

export const fetchSingleProductMasterData = async (productId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/${productId}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('addProduct', response);
    return response;
  } catch (error) {
    logger('addProductERROR : ', error);
    throw error.message;
  }
};

export const addProductApi = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/product`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('add product', response);
    return response;
  } catch (error) {
    logger('add product Error : ', error);
    throw error.message;
  }
};

export const updateProductApi = async ({
  PRODUCT_ID,
  PRODUCT_CODE,
  PRODUCT_NAME,
  PRODUCT_DESC,
  PRODUCT_LOGO
}) => {
  try {
    const getData = await axios.put(`${process.env.REACT_APP_API_URL}/product/update`, {
      PRODUCT_ID,
      PRODUCT_CODE,
      PRODUCT_NAME,
      PRODUCT_DESC,
      PRODUCT_LOGO
    });
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('updateProduct', response);
    return response;
  } catch (error) {
    // logger('updateProductERROR : ', error);
    throw error.message;
  }
};
export const removeProductByIdApi = async (id) => {
  try {
    const getData = await axios.delete(`${process.env.REACT_APP_API_URL}/product/${id}`);
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('remProductERROR : ', error);
    throw error.message;
  }
};
