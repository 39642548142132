/* eslint-disable */
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Stack,
  IconButton
} from '@material-ui/core';
import { TableLoading } from 'src/customComponents/loadingScreen';
import {
  StyledTableCell,
  useStyles,
  StyledChip
} from 'src/components/common/tableStyles';

import { statusCheck, statusEnableDisable } from 'src/utils/statusCheck';
import { Delete, Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router';

const PaymentSequenceList = ({
  isLoading,
  pageNumber,
  dataLimit,
  tableData,
  pagination,
  ...rest
}) => {
  const handleChange = (event, value) => pageNumber(value);
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{}}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Action</StyledTableCell>
                  <StyledTableCell>Id</StyledTableCell>
                  <StyledTableCell>Product Id</StyledTableCell>
                  <StyledTableCell>Plan Id</StyledTableCell>
                  <StyledTableCell>Payment Id</StyledTableCell>
                  <StyledTableCell>Display Order</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Platfrom Id</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoading rows={6} columns={10} />
                ) : (
                  tableData.map((payment) => (
                    <TableRow hover key={payment.ID} style={{ padding: 0 }}>
                      <StyledTableCell>
                        <Stack direction="row" spacing={0}>
                          <IconButton
                            color="default"
                            aria-label="edit"
                            onClick={(e) => {
                              if (payment?.ID) {
                                navigate(`/payment/sequence/edit/${payment?.ID}`);
                              }
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton color="error" aria-label="del" onClick={(e) => {}}>
                            <Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {payment.ID}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{payment.PRODUCT_ID}</StyledTableCell>
                      <StyledTableCell>{payment.PLAN_ID}</StyledTableCell>
                      <StyledTableCell>{payment.PAYMENT_ID}</StyledTableCell>
                      <StyledTableCell>{payment.DISPLAY_ORDER}</StyledTableCell>
                      <StyledTableCell>
                        <StyledChip
                          label={statusEnableDisable(payment?.STATUS).text}
                          color={statusEnableDisable(payment?.STATUS).color}
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell>{payment.PLATFORM_ID}</StyledTableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          count={pagination?.total_pages ? pagination?.total_pages : 0}
          onChange={handleChange}
          page={pagination?.current_page ? pagination?.current_page : 0}
          color="primary"
          className={classes.pagination}
          showFirstButton
          showLastButton
          sx={{
            display: 'flex',
            m: 2,
            justifyContent: 'right'
          }}
        />
      </Card>
    </div>
  );
};

PaymentSequenceList.propTypes = {
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  pageNumber: PropTypes.func,
  dataLimit: PropTypes.func,
  pagination: PropTypes.object
};

export default PaymentSequenceList;
