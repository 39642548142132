import axios from 'axios';
// import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const getPlanMasterApi = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/master?page=${page}&view=${limit}&getAll=${getAll}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};

export const getAllActivePlanMasterApi = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/master/activePlan?page=${page}&view=${limit}&getAll=${getAll}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};
export const getPlanMasterByIdApi = async (planId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/master/${planId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};

export const getSearchPlanMasterApi = async (page, limit, input) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/master/search?page=${page}&view=${limit}&input=${input}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('paymentPlatformERROR : ', error);
    throw error.message;
  }
};

export const addPlanMasterApi = async ({
  PLAN_NAME,
  DURATION_IN_DAYS,
  PLAN_TYPE,
  RENEWABLE,
  STATUS,
  ADDED_ON,
  ADDED_BY,
  LAST_MODIFIED
}) => {
  try {
    const getData = await axios.post(`${process.env.REACT_APP_API_URL}/plan/master`, {
      PLAN_NAME,
      DURATION_IN_DAYS,
      PLAN_TYPE,
      RENEWABLE,
      STATUS,
      ADDED_ON,
      ADDED_BY,
      LAST_MODIFIED
    });
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const updatePlanMasterApi = async ({
  PLAN_ID,
  PLAN_NAME,
  DURATION_IN_DAYS,
  PLAN_TYPE,
  RENEWABLE,
  STATUS,
  LAST_MODIFIED,
  ADDED_BY
}) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/plan/master/update`,
      {
        PLAN_ID,
        PLAN_NAME,
        DURATION_IN_DAYS,
        PLAN_TYPE,
        RENEWABLE,
        STATUS,
        LAST_MODIFIED,
        ADDED_BY
      }
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const removePlanMasterByIdApi = async (planId) => {
  try {
    const getData = await axios.delete(
      `${process.env.REACT_APP_API_URL}/plan/master/${planId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};
