import React from 'react';
import PlanMasterForm from 'src/components/plans/master/PlanMasterForm';

const AddEditPlanMaster = () => (
  <div>
    <PlanMasterForm />
  </div>
);

export default AddEditPlanMaster;
