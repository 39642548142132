import React from 'react';
import {
  Container,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormLabel,
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchemaValue = yup.object({
  CouponToGenerate: yup.number().required('Coupon Generate is required'),
  StartDate: yup.date().required('start date is required').min(new Date(), 'date must be forwarded date'),
  EndDate: yup.date().required('end date is required').min(new Date(), 'date must be forwarded date')

});

const AddCouponForm = () => {
  const formik = useFormik({
    initialValues: {
      CouponToGenerate: '',
      StartDate: '',
      EndDate: ''
    },
    validationSchema: validationSchemaValue,
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
    },
  });

  return (
    <Container style={{ marginTop: '1rem' }}>
      <h3>Add New Coupons</h3>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          margin: 2
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Offer
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Offer"
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Plan Id
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Plan Id"
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="Downloads"
                name="downloads"
              // defaultValue="Plan Country"
              // value={formik.values.PlanCountry}
              // onChange={formik.handleChange}
              // error={formik.touched.PlanCountry && Boolean(formik.errors.PlanCountry)}
              // helperText={formik.touched.PlanCountry && formik.errors.PlanCountry}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Coupon Types
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Coupon Types"
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="Coupon Prefix"
                name="CouponPrefix"
              // defaultValue="Plan Country"
              // value={formik.values.PlanCountry}
              // onChange={formik.handleChange}
              // error={formik.touched.PlanCountry && Boolean(formik.errors.PlanCountry)}
              // helperText={formik.touched.PlanCountry && formik.errors.PlanCountry}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="Coupon Length"
                name="CouponLength"
                type="number"
              // defaultValue="Plan Country"
              // value={formik.values.PlanCountry}
              // onChange={formik.handleChange}
              // error={formik.touched.PlanCountry && Boolean(formik.errors.PlanCountry)}
              // helperText={formik.touched.PlanCountry && formik.errors.PlanCountry}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="No. Of Coupon to Generate"
                name="CouponToGenerate"
                // defaultValue="Plan Country"
                value={formik.values.CouponToGenerate}
                onChange={formik.handleChange}
                error={formik.touched.CouponToGenerate && Boolean(formik.errors.CouponToGenerate)}
                helperText={formik.touched.CouponToGenerate && formik.errors.CouponToGenerate}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <FormLabel component="legend">Start Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                name="StartDate"
                type="date"
                // defaultValue="Plan Country"
                value={formik.values.StartDate}
                onChange={formik.handleChange}
                error={formik.touched.StartDate && Boolean(formik.errors.StartDate)}
                helperText={formik.touched.StartDate && formik.errors.StartDate}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <FormLabel component="legend">End Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                name="EndDate"
                type="date"
                // defaultValue="Plan Country"
                value={formik.values.EndDate}
                onChange={formik.handleChange}
                error={formik.touched.EndDate && Boolean(formik.errors.EndDate)}
                helperText={formik.touched.EndDate && formik.errors.EndDate}

              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="Duration In Days"
                name="DurationInDays"
              // defaultValue="Plan Country"
              // value={formik.values.PlanCountry}
              // onChange={formik.handleChange}
              // error={formik.touched.PlanCountry && Boolean(formik.errors.PlanCountry)}
              // helperText={formik.touched.PlanCountry && formik.errors.PlanCountry}

              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, maxWidth: 140, margin: 1 }}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Box>
        </form>

      </Paper>

    </Container>
  );
};
export default AddCouponForm;
