import OrderReportForm from 'src/components/report/order/OrderReportForm';
import { Helmet } from 'react-helmet';

const OrderReport = () => (
  <>
    <Helmet>
      <title> Order Report </title>
    </Helmet>
    <div>
      <OrderReportForm />
    </div>
  </>
);

export default OrderReport;
