/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getAllActivePlanMasterApi } from 'src/services/plans/planMaster';
import { fetchPaymentMasterApi } from 'src/services/payment/paymentMaster';
import { fetchPlatformsApi } from 'src/services/platform/platform';
import CustomNotifier from 'src/customComponents/Notifier';
import DatePicker from '@mui/lab/DatePicker';
import { updateAssignSubscriptionApi } from 'src/services/user/orderApi';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  identity: yup.string().required('User ID or Identity is required'),
  start_date: yup.string().required('Start Date is required'),
  end_date: yup.string().required('End Date is required'),
  PLAN_ID: yup.string().required('Plan is required'),
  PAYMENT_ID: yup.string().required('Payment is required'),
  PLATFORM_ID: yup.string().required('Platform is required'),
  remark: yup.string().required('Remark is required'),
  user_name: yup.string().required('User Name is required').optional()
});

const AssignSubscriptionForm = () => {
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };
  const [plan, setPlan] = useState([]);
  const [payment, setPayment] = useState([]);
  const [platform, setPlatform] = useState([]);


  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      identity: '',
      start_date: '',
      end_date: '',
      PLAN_ID:'',
      PAYMENT_ID:'',
      PLATFORM_ID:'',
      remark: '',
      user_name: ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        start_date: dayjs(value.start_date).format('YYYY-MM-DD HH:MM'),
        end_date: dayjs(value.end_date).format('YYYY-MM-DD HH:MM'),
        PLAN_ID: value.PLAN_ID,
        PAYMENT_ID: value.PAYMENT_ID,
        PLATFORM_ID: value.PLATFORM_ID,
        identity: value.identity.toString()

        // LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };

      console.log('values:::', obj);
      updateAssignSubscriptionApi({ ...obj })
        .then((res) => {
          // console.log('res master ::', res);
          setSnackBarOpen(true, res.message);
        })
        .catch((error) => {
          setSnackBarOpen(true, error);
        });
    }
  });

  useEffect(() => {
    //Plan
    getAllActivePlanMasterApi(1, 10, true)
      .then((res) => {
        // console.log(res);
        setPlan(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

      fetchPaymentMasterApi(1, 10, true)
      .then((res) => {
        // console.log(res);
        setPayment(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

      fetchPlatformsApi(1, 10, true)
      .then((res) => {
        // console.log(res);
        setPlatform(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });
  }, []);


  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {'Update Or Assign New Subscription'}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Identity"
              style={{ width: '100%', marginBottom: '10px' }}
              name="identity"
              value={formik.values.identity}
              onChange={formik.handleChange}
              error={formik.touched.identity && Boolean(formik.errors.identity)}
              helperText={formik.touched.identity && formik.errors.identity}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Username"
              style={{ width: '100%', marginBottom: '10px' }}
              name="user_name"
              value={formik.values.user_name}
              onChange={formik.handleChange}
              error={formik.touched.user_name && Boolean(formik.errors.user_name)}
              helperText={formik.touched.user_name && formik.errors.user_name}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimeField']}>
                <DateTimeField
                  label="Order Date"
                  value={
                    formik.values.ORDER_DATE ? dayjs(formik.values.ORDER_DATE * 1000) : ''
                  }
                  disabled
                  onChange={formik.handleChange}
                  error={formik.touched.ORDER_DATE && Boolean(formik.errors.ORDER_DATE)}
                  helperText={formik.touched.ORDER_DATE && formik.errors.ORDER_DATE}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">Start Date</FormLabel>
            <TextField
              label=""
              style={{ width: '100%', marginBottom: '10px' }}
              name="start_date"
              type="datetime-local"
              value={formik.values.start_date}
              onChange={formik.handleChange}
              error={formik.touched.start_date && Boolean(formik.errors.start_date)}
              helperText={formik.touched.start_date && formik.errors.start_date}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">End Date</FormLabel>
            <TextField
              label=""
              style={{ width: '100%', marginBottom: '10px' }}
              name="end_date"
              type="datetime-local"
              format="YYYY-MM-DD HH:MM:SS"
              value={formik.values.end_date}
              onChange={formik.handleChange}
              error={formik.touched.end_date && Boolean(formik.errors.end_date)}
              helperText={formik.touched.end_date && formik.errors.end_date}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
              <FormLabel component="legend">Select Plan</FormLabel>
              <Select
                label ='Select Plan'
                style={{ width: '100%', marginBottom: '10px' }}
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                name="plan"
                value={formik.values.plan_id}
                onChange={formik.handleChange}
              >
                <MenuItem value={'2'}>{'WEEKLY'}</MenuItem>
                <MenuItem value={'3'}>{'MONTHLY'}</MenuItem>
                <MenuItem value={'8'}>{'QUARTERLY'}</MenuItem>
                <MenuItem value={'10'}>{'YEARLY'}</MenuItem>
              </Select>
              <FormHelperText>
                {formik.touched.plan_id && formik.errors.plan_id}
              </FormHelperText>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PLAN_ID && formik.errors.PLAN_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
              Select Plan
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Plan"
                name="PLAN_ID"
                value={formik.values.PLAN_ID}
                onChange={formik.handleChange}
              >
                {plan?.map((el, i) => (
                  // <MenuItem value={el?.PLAN_ID}>{el?.PLAN_NAME}</MenuItem>
                  <MenuItem key={el.PLAN_ID} value={el}>
                    {el.PLAN_NAME}
                  </MenuItem>
                ))}
                {!plan && <MenuItem value={''}>--No Plan--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PLAN_ID && formik.errors.PLAN_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
              Select Payment
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Payment"
                name="PAYMENT_ID"
                value={formik.values.PAYMENT_ID}
                onChange={formik.handleChange}
              >
                {payment?.map((el, i) => (
                  // <MenuItem value={el?.PLAN_ID}>{el?.PLAN_NAME}</MenuItem>
                  <MenuItem key={el.PAYMENT_ID} value={el}>
                    {el.PAYMENT_NAME}
                  </MenuItem>
                ))}
                {!payment && <MenuItem value={''}>--No Payment--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
              Select Platform
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Platform"
                name="PLATFORM_ID"
                value={formik.values.PLATFORM_ID}
                onChange={formik.handleChange}
              >
                {platform?.map((el, i) => (
                  // <MenuItem value={el?.PLAN_ID}>{el?.PLAN_NAME}</MenuItem>
                  <MenuItem key={el.PLATFORM_ID} value={el}>
                    {el.PLATFORM_NAME}
                  </MenuItem>
                ))}
                {!platform && <MenuItem value={''}>--No Platform--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Remark"
              style={{ width: '100%', marginBottom: '10px' }}
              name="remark"
              value={formik.values.remark}
              onChange={formik.handleChange}
              error={formik.touched.remark && Boolean(formik.errors.remark)}
              helperText={formik.touched.remark && formik.errors.remark}
            />
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/order/orders');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default AssignSubscriptionForm;
