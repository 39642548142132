/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  addPlanMasterApi,
  getPlanMasterByIdApi,
  updatePlanMasterApi
} from 'src/services/plans/planMaster';
import CustomNotifier from 'src/customComponents/Notifier';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  PLAN_NAME: yup.string().required('Plan Name is required'),
  DURATION_IN_DAYS: yup.number().required('Duration is required'),
  PLAN_TYPE: yup.string('should be string').required('Plan Type is required')
  // STATUS: yup.boolean().required('Status is required'),
  // RENEWABLE: yup.boolean().required('select renewable ')
});

const PlanMasterForm = () => {
  const { planMastId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [editPlanDetails, setEditPlanDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      PLAN_NAME: editPlanDetails['PLAN_NAME'] ? editPlanDetails['PLAN_NAME'] : '',
      DURATION_IN_DAYS: editPlanDetails['DURATION_IN_DAYS']
        ? editPlanDetails['DURATION_IN_DAYS']
        : '',
      PLAN_TYPE: editPlanDetails['PLAN_TYPE'] ? editPlanDetails['PLAN_TYPE'] : '',
      RENEWABLE: editPlanDetails['RENEWABLE']?.toString()
        ? editPlanDetails['RENEWABLE']?.toString()
        : false,
      STATUS: editPlanDetails['STATUS']?.toString()
        ? editPlanDetails['STATUS']?.toString()
        : '0'
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        PLAN_ID: planMastId ? planMastId : '',
        DURATION_IN_DAYS: parseInt(value['DURATION_IN_DAYS']),
        RENEWABLE: value['RENEWABLE'] ? 1 : 0,
        STATUS: parseInt(value['STATUS']),
        LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };
      if (planMastId) {
        updatePlanMasterApi({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addPlanMasterApi({ ...obj, ADDED_ON: addedON, ADDED_BY: addedBy })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }

      // console.log(value, obj);
    }
  });

  useEffect(() => {
    //edit api
    if (planMastId) {
      getPlanMasterByIdApi(planMastId)
        .then((res) => {
          setEditPlanDetails(res?.tableData[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/plan/master');
        });
    }
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {planMastId ? 'Edit' : 'Add New'} Plan
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Plan Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_NAME"
              value={formik.values.PLAN_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_NAME && Boolean(formik.errors.PLAN_NAME)}
              helperText={formik.touched.PLAN_NAME && formik.errors.PLAN_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Duration(Days)"
              type="number"
              style={{ width: '100%', marginBottom: '10px' }}
              name="DURATION_IN_DAYS"
              value={formik.values.DURATION_IN_DAYS}
              onChange={formik.handleChange}
              error={
                formik.touched.DURATION_IN_DAYS && Boolean(formik.errors.DURATION_IN_DAYS)
              }
              helperText={
                formik.touched.DURATION_IN_DAYS && formik.errors.DURATION_IN_DAYS
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_TYPE"
              value={formik.values.PLAN_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_TYPE && Boolean(formik.errors.PLAN_TYPE)}
              helperText={formik.touched.PLAN_TYPE && formik.errors.PLAN_TYPE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="RENEWABLE" />}
              label="Renewable"
              checked={formik.values.RENEWABLE}
              // value={formik.values.RENEWABLE}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              component="fieldset"
              error={formik.touched.radioGroup && Boolean(formik.errors.radioGroup)}
            >
              <FormLabel component="legend">Status</FormLabel>

              <RadioGroup
                name="STATUS"
                value={formik.values.STATUS}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="1" control={<Radio />} label="Active" />
                  <FormControlLabel value="0" control={<Radio />} label="De-Active" />
                </div>
              </RadioGroup>
              <FormHelperText>
                {formik.touched.STATUS && formik.errors.STATUS}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/plan/master');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default PlanMasterForm;
