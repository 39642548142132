import EditViewOrderForm from 'src/components/order/OrderForm';
import { Helmet } from 'react-helmet';

const OrderDetails = () => (
  <>
    <Helmet>
      <title>Order </title>
    </Helmet>
    <div>
      <EditViewOrderForm />
    </div>
  </>
);

export default OrderDetails;
