/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import DatePicker from '@mui/lab/DatePicker';
import { fetchSingleOrderById, updateOrderApi } from 'src/services/user/orderApi';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  // ORDER_ID: yup.string().required('Order ID is required'),
  // IDENTITY: yup.string().required('Identity is required'),
  // ORDER_DATE: yup.string().required('Order Date is required'),
  // START_DATE: yup.string().required('Start Date is required'),
  // END_DATE: yup.string().required('End Date is required'),
  // ORDER_STATUS: yup.string().required('Order Status is required'),
  // VALIDITY_STATUS: yup.string().required('Validity Status is required')
  // PLAN_AMOUNT: yup.string().required('Plan Amount is required'),
  // TXN_AMOUNT: yup.string().required('Transaction Amount is required')
  // EVENT_TYPE: yup.string().required('Event Type is required'),
  // PRODUCT_ID: yup.string().required('Product Id is required'),
  // PRODUCT_NAME: yup.string().required('Product Name is required'),
  // PLATFORM_ID: yup.string().required('Platform ID is required'),
  // PLATFORM_NAME: yup.string().required('Platform Name is required'),
  // PAYMENT_ID: yup.string().required('PAYMENT ID is required'),
  // PAYMENT_NAME: yup.string().required('PAYMENT Name is required'),
  // PLAN_ID: yup.string().required('Plan ID is required'),
  // PLAN_NAME: yup.string().required('Plan Name is required'),
  // PLAN_DETAILS_ID: yup.string().required('Plan Details ID is required'),
  // PLAN_CURRENCEY: yup.string().required('Plan Currencey is required'),
  // PLAN_TYPE: yup.string().required('Plan type is required')
});

const OrderForm = () => {
  const { ordID } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [editOrderDetails, setEditOrderDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ORDER_ID: editOrderDetails['ORDER_ID']
        ? editOrderDetails['ORDER_ID'].toString()
        : '',
      IDENTITY: editOrderDetails['IDENTITY'] ? editOrderDetails['IDENTITY'] : '',
      ORDER_DATE: editOrderDetails['ORDER_DATE']
        ? editOrderDetails['ORDER_DATE'].toString()
        : '',
      START_DATE: editOrderDetails['START_DATE'] ? editOrderDetails['START_DATE'] : null,
      END_DATE: editOrderDetails['END_DATE'] ? editOrderDetails['END_DATE'] : null,
      Estimated_END_DATE: editOrderDetails['Estimated_END_DATE'] ? editOrderDetails['Estimated_END_DATE'] : null,
      ORDER_STATUS: editOrderDetails['ORDER_STATUS']
        ? parseInt(editOrderDetails['ORDER_STATUS'])
        : '',
      VALIDITY_STATUS: editOrderDetails['VALIDITY_STATUS']
        ? parseInt(editOrderDetails['VALIDITY_STATUS'])
        : '',
      PLAN_AMOUNT: editOrderDetails['PLAN_AMOUNT'] ? editOrderDetails['PLAN_AMOUNT'] : '',
      TXN_AMOUNT: editOrderDetails['TXN_AMOUNT'] ? editOrderDetails['TXN_AMOUNT'] : '',
      EVENT_TYPE: editOrderDetails['EVENT_TYPE']
        ? editOrderDetails['EVENT_TYPE'].toString()
        : '',
      PRODUCT_ID: editOrderDetails['PRODUCT_ID'] ? editOrderDetails['PRODUCT_ID'] : '',
      PRODUCT_NAME: editOrderDetails['PRODUCT_NAME']
        ? editOrderDetails['PRODUCT_NAME'].toString()
        : '',
      PLATFORM_ID: editOrderDetails['PLATFORM_ID'] ? editOrderDetails['PLATFORM_ID'] : '',
      PLATFORM_NAME: editOrderDetails['PLATFORM_NAME']
        ? editOrderDetails['PLATFORM_NAME'].toString()
        : '',
      PAYMENT_ID: editOrderDetails['PAYMENT_ID'] ? editOrderDetails['PAYMENT_ID'] : '',
      PAYMENT_NAME: editOrderDetails['PAYMENT_NAME']
        ? editOrderDetails['PAYMENT_NAME'].toString()
        : '',
      PLAN_ID: editOrderDetails['PLAN_ID'] ? editOrderDetails['PLAN_ID'] : '',
      PLAN_NAME: editOrderDetails['PLAN_NAME']
        ? editOrderDetails['PLAN_NAME'].toString()
        : '',
      PLAN_DETAILS_ID: editOrderDetails['PLAN_DETAILS_ID']
        ? editOrderDetails['PLAN_DETAILS_ID']
        : '',
      PLAN_CURRENCY: editOrderDetails['PLAN_CURRENCY']
        ? editOrderDetails['PLAN_CURRENCY'].toString()
        : '',
      PLAN_TYPE: editOrderDetails['PLAN_TYPE']
        ? editOrderDetails['PLAN_TYPE'].toString()
        : '',
      PLAN_DURATION: editOrderDetails['PLAN_DURATION']
        ? editOrderDetails['PLAN_DURATION'].toString()
        : '',
      REMARK: editOrderDetails['REMARK'] ? editOrderDetails['REMARK'].toString() : '',
      AFF_CODE: editOrderDetails['AFF_CODE']
        ? editOrderDetails['AFF_CODE'].toString()
        : '',
      HARDWARE_ID: editOrderDetails['HARDWARE_ID']
        ? editOrderDetails['HARDWARE_ID'].toString()
        : '',
      MSISDN: editOrderDetails['MSISDN'] ? editOrderDetails['MSISDN'].toString() : '',
      ADD_ID: editOrderDetails['ADD_ID'] ? editOrderDetails['ADD_ID'].toString() : '',
      AFFILITE_ID: editOrderDetails['AFFILITE_ID']
        ? editOrderDetails['AFFILITE_ID'].toString()
        : '',
      AFFILITE_NAME: editOrderDetails['AFFILITE_NAME']
        ? editOrderDetails['AFFILITE_NAME'].toString()
        : '',
      COUPON_CODE: editOrderDetails['COUPON_CODE']
        ? editOrderDetails['COUPON_CODE'].toString()
        : '',
      REFERER_SOURCE: editOrderDetails['REFERER_SOURCE']
        ? editOrderDetails['REFERER_SOURCE'].toString()
        : '',
      EXTRA_DATA: editOrderDetails['EXTRA_DATA']
        ? editOrderDetails['EXTRA_DATA'].toString()
        : '',
      ORDER_COIN: editOrderDetails['ORDER_COIN'] ? editOrderDetails['ORDER_COIN'] : '',
      CONTENT_ID_LIST: editOrderDetails['CONTENT_ID_LIST']
        ? editOrderDetails['CONTENT_ID_LIST']
        : '',
      IS_SUB_ORDERS: editOrderDetails['IS_SUB_ORDERS']
        ? editOrderDetails['IS_SUB_ORDERS'].toString()
        : '',
      MONTH: editOrderDetails['MONTH'] ? editOrderDetails['MONTH'] : '',
      YEAR: editOrderDetails['YEAR'] ? editOrderDetails['YEAR'] : '',
      RENEWAL_RETRY: editOrderDetails['RENEWAL_RETRY']
        ? editOrderDetails['RENEWAL_RETRY']
        : '',
      PREV_ORDER_ID: editOrderDetails['PREV_ORDER_ID']
        ? editOrderDetails['PREV_ORDER_ID'].toString()
        : '',
      PG_TYPE: editOrderDetails['PG_TYPE'] ? editOrderDetails['PG_TYPE'].toString() : '',
      IS_MIGRATED_ORDER: editOrderDetails['IS_MIGRATED_ORDER']
        ? editOrderDetails['IS_MIGRATED_ORDER']
        : '',
      IS_FREE_TRIAL: editOrderDetails['IS_FREE_TRIAL']
        ? editOrderDetails['IS_FREE_TRIAL']
        : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        START_DATE: dayjs(value.START_DATE).unix(),
        END_DATE: dayjs(value.END_DATE).unix(),
        ID: ordID ? ordID : '',
        USER_ID: addedBy
        // LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };
      if (ordID) {
        updateOrderApi({ ...obj })
          .then((res) => {
            // console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }

      console.log('values:::', obj);
    }
  });

  useEffect(() => {
    //edit api
    if (ordID) {
      fetchSingleOrderById(ordID)
        .then((res) => {
          setEditOrderDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/order/orders');
        });
    }
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {ordID ? 'Edit/View' : ''} Order
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Order Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ORDER_ID"
              value={formik.values.ORDER_ID}
              onChange={formik.handleChange}
              error={formik.touched.ORDER_ID && Boolean(formik.errors.ORDER_ID)}
              helperText={formik.touched.ORDER_ID && formik.errors.ORDER_ID}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Identity"
              style={{ width: '100%', marginBottom: '10px' }}
              name="IDENTITY"
              disabled
              value={formik.values.IDENTITY}
              onChange={formik.handleChange}
              error={formik.touched.IDENTITY && Boolean(formik.errors.IDENTITY)}
              helperText={formik.touched.IDENTITY && formik.errors.IDENTITY}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Order Date"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ORDER_DATE"
              disabled
              color="info"
              value={formik.values.ORDER_DATE}
              onChange={formik.handleChange}
              error={formik.touched.ORDER_DATE && Boolean(formik.errors.ORDER_DATE)}
              helperText={formik.touched.ORDER_DATE && formik.errors.ORDER_DATE}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimeField']}>
                <DateTimeField
                  label="Order Date (mm/dd/yyyy)"
                  value={
                    formik.values.ORDER_DATE ? dayjs(formik.values.ORDER_DATE * 1000) : ''
                  }
                  disabled
                  onChange={formik.handleChange}
                  error={formik.touched.ORDER_DATE && Boolean(formik.errors.ORDER_DATE)}
                  helperText={formik.touched.ORDER_DATE && formik.errors.ORDER_DATE}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              style={{ width: '100%', marginBottom: '10px' }}
              name="START_DATE"
              type="datetime-local"
              value={formik.values.START_DATE}
              onChange={formik.handleChange}
              error={formik.touched.START_DATE && Boolean(formik.errors.START_DATE)}
              helperText={formik.touched.START_DATE && formik.errors.START_DATE}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimeField']}>
                <DateTimeField
                  label="Start Date"
                  value={
                    formik.values.START_DATE ? dayjs(formik.values.START_DATE * 1000) : ''
                  }
                  disabled
                  onChange={formik.handleChange}
                  error={formik.touched.START_DATE && Boolean(formik.errors.START_DATE)}
                  helperText={formik.touched.START_DATE && formik.errors.START_DATE}
                />
              </DemoContainer>
            </LocalizationProvider> */}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  onViewChange={formik.handleChange}
                  value={
                    formik.values.START_DATE ? dayjs(formik.values.START_DATE * 1000) : ''
                  }
                  slotProps={{
                    textField: {
                      label: 'Start Date',
                      name: 'START_DATE',
                      variant: 'outlined',

                      error:
                        formik.touched.START_DATE && Boolean(formik.errors.START_DATE),
                      helperText: formik.touched.START_DATE && formik.errors.START_DATE
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              style={{ width: '100%', marginBottom: '10px' }}
              name="END_DATE"
              type="datetime-local"
              value={formik.values.END_DATE}
              onChange={formik.handleChange}
              error={formik.touched.END_DATE && Boolean(formik.errors.END_DATE)}
              helperText={formik.touched.END_DATE && formik.errors.END_DATE}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimeField']}>
                <DateTimeField
                  label="End Date"
                  value={
                    formik.values.END_DATE ? dayjs(formik.values.END_DATE * 1000) : ''
                  }
                  disabled
                  onChange={formik.handleChange}
                  error={formik.touched.END_DATE && Boolean(formik.errors.END_DATE)}
                  helperText={formik.touched.END_DATE && formik.errors.END_DATE}
                />
              </DemoContainer>
            </LocalizationProvider> */}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  onViewChange={(e) => {
                    console.log('change end date::', e);
                  }}
                  value={
                    formik.values.END_DATE ? dayjs(formik.values.END_DATE * 1000) : ''
                  }
                  slotProps={{
                    textField: {
                      label: 'End Date',
                      name: 'END_DATE',
                      variant: 'outlined',

                      error: formik.touched.END_DATE && Boolean(formik.errors.END_DATE),
                      helperText: formik.touched.END_DATE && formik.errors.END_DATE
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Order Status"
              type="number"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ORDER_STATUS"
              value={formik.values.ORDER_STATUS}
              onChange={formik.handleChange}
              error={formik.touched.ORDER_STATUS && Boolean(formik.errors.ORDER_STATUS)}
              helperText={formik.touched.ORDER_STATUS && formik.errors.ORDER_STATUS}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimeField']}>
                <DateTimeField
                  label="Estimated End Date (mm/dd/yyyy)"
                  value={
                    formik.values.Estimated_END_DATE ? dayjs(formik.values.Estimated_END_DATE * 1000) : ''
                  }
                  disabled
                  onChange={formik.handleChange}
                  error={formik.touched.Estimated_END_DATE && Boolean(formik.errors.Estimated_END_DATE)}
                  helperText={formik.touched.Estimated_END_DATE && formik.errors.Estimated_END_DATE}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Duration"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_DURATION"
              disabled
              value={formik.values.PLAN_DURATION}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_DURATION && Boolean(formik.errors.PLAN_DURATION)}
              helperText={formik.touched.PLAN_DURATION && formik.errors.PLAN_DURATION}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Validity Status"
              style={{ width: '100%', marginBottom: '10px' }}
              type="number"
              name="VALIDITY_STATUS"
              value={formik.values.VALIDITY_STATUS}
              onChange={formik.handleChange}
              error={
                formik.touched.VALIDITY_STATUS && Boolean(formik.errors.VALIDITY_STATUS)
              }
              helperText={formik.touched.VALIDITY_STATUS && formik.errors.VALIDITY_STATUS}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Amount"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_AMOUNT"
              disabled
              value={formik.values.PLAN_AMOUNT}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_AMOUNT && Boolean(formik.errors.PLAN_AMOUNT)}
              helperText={formik.touched.PLAN_AMOUNT && formik.errors.PLAN_AMOUNT}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Transaction Amount"
              style={{ width: '100%', marginBottom: '10px' }}
              name="TXN_AMOUNT"
              disabled
              value={formik.values.TXN_AMOUNT}
              onChange={formik.handleChange}
              error={formik.touched.TXN_AMOUNT && Boolean(formik.errors.TXN_AMOUNT)}
              helperText={formik.touched.TXN_AMOUNT && formik.errors.TXN_AMOUNT}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Event Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="EVENT_TYPE"
              disabled
              value={formik.values.EVENT_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.EVENT_TYPE && Boolean(formik.errors.EVENT_TYPE)}
              helperText={formik.touched.EVENT_TYPE && formik.errors.EVENT_TYPE}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_ID"
              disabled
              value={formik.values.PRODUCT_ID}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_ID && Boolean(formik.errors.PRODUCT_ID)}
              helperText={formik.touched.PRODUCT_ID && formik.errors.PRODUCT_ID}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_NAME"
              disabled
              value={formik.values.PRODUCT_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_NAME && Boolean(formik.errors.PRODUCT_NAME)}
              helperText={formik.touched.PRODUCT_NAME && formik.errors.PRODUCT_NAME}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Platform Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLATFORM_ID"
              disabled
              value={formik.values.PLATFORM_ID}
              onChange={formik.handleChange}
              error={formik.touched.PLATFORM_ID && Boolean(formik.errors.PLATFORM_ID)}
              helperText={formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Platform Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLATFORM_NAME"
              disabled
              value={formik.values.PLATFORM_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PLATFORM_NAME && Boolean(formik.errors.PLATFORM_NAME)}
              helperText={formik.touched.PLATFORM_NAME && formik.errors.PLATFORM_NAME}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_ID"
              disabled
              value={formik.values.PAYMENT_ID}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_ID && Boolean(formik.errors.PAYMENT_ID)}
              helperText={formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_NAME"
              disabled
              value={formik.values.PAYMENT_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_NAME && Boolean(formik.errors.PAYMENT_NAME)}
              helperText={formik.touched.PAYMENT_NAME && formik.errors.PAYMENT_NAME}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_ID"
              disabled
              value={formik.values.PLAN_ID}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_ID && Boolean(formik.errors.PLAN_ID)}
              helperText={formik.touched.PLAN_ID && formik.errors.PLAN_ID}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_NAME"
              disabled
              value={formik.values.PLAN_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_NAME && Boolean(formik.errors.PLAN_NAME)}
              helperText={formik.touched.PLAN_NAME && formik.errors.PLAN_NAME}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Details Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_DETAILS_ID"
              disabled
              value={formik.values.PLAN_DETAILS_ID}
              onChange={formik.handleChange}
              error={
                formik.touched.PLAN_DETAILS_ID && Boolean(formik.errors.PLAN_DETAILS_ID)
              }
              helperText={formik.touched.PLAN_DETAILS_ID && formik.errors.PLAN_DETAILS_ID}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Currencey"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_CURRENCEY"
              disabled
              value={formik.values.PLAN_CURRENCEY}
              onChange={formik.handleChange}
              error={
                formik.touched.PLAN_CURRENCEY && Boolean(formik.errors.PLAN_CURRENCEY)
              }
              helperText={formik.touched.PLAN_CURRENCEY && formik.errors.PLAN_CURRENCEY}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_TYPE"
              value={formik.values.PLAN_TYPE}
              disabled
              onChange={formik.handleChange}
              error={formik.touched.PLAN_TYPE && Boolean(formik.errors.PLAN_TYPE)}
              helperText={formik.touched.PLAN_TYPE && formik.errors.PLAN_TYPE}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Duration"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLAN_DURATION"
              disabled
              value={formik.values.PLAN_DURATION}
              onChange={formik.handleChange}
              error={formik.touched.PLAN_DURATION && Boolean(formik.errors.PLAN_DURATION)}
              helperText={formik.touched.PLAN_DURATION && formik.errors.PLAN_DURATION}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              label="Remark"
              style={{ width: '100%', marginBottom: '10px' }}
              name="REMARK"
              value={formik.values.REMARK}
              onChange={formik.handleChange}
              error={formik.touched.REMARK && Boolean(formik.errors.REMARK)}
              helperText={formik.touched.REMARK && formik.errors.REMARK}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Aff Code"
              style={{ width: '100%', marginBottom: '10px' }}
              name="AFF_CODE"
              disabled
              value={formik.values.AFF_CODE}
              onChange={formik.handleChange}
              error={formik.touched.AFF_CODE && Boolean(formik.errors.AFF_CODE)}
              helperText={formik.touched.AFF_CODE && formik.errors.AFF_CODE}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Hardware Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="HARDWARE_ID"
              disabled
              value={formik.values.HARDWARE_ID}
              onChange={formik.handleChange}
              error={formik.touched.HARDWARE_ID && Boolean(formik.errors.HARDWARE_ID)}
              helperText={formik.touched.HARDWARE_ID && formik.errors.HARDWARE_ID}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="MSISDN"
              style={{ width: '100%', marginBottom: '10px' }}
              name="MSISDN"
              disabled
              value={formik.values.MSISDN}
              onChange={formik.handleChange}
              error={formik.touched.MSISDN && Boolean(formik.errors.MSISDN)}
              helperText={formik.touched.MSISDN && formik.errors.MSISDN}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="ADDID"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ADDID"
              disabled
              value={formik.values.ADDID}
              onChange={formik.handleChange}
              error={formik.touched.ADDID && Boolean(formik.errors.ADDID)}
              helperText={formik.touched.ADDID && formik.errors.ADDID}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="ADDID"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ADDID"
              disabled
              value={formik.values.ADDID}
              onChange={formik.handleChange}
              error={formik.touched.ADDID && Boolean(formik.errors.ADDID)}
              helperText={formik.touched.ADDID && formik.errors.ADDID}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="AFFILITE ID"
              style={{ width: '100%', marginBottom: '10px' }}
              name="AFFILITE_ID"
              disabled
              value={formik.values.AFFILITE_ID}
              onChange={formik.handleChange}
              error={formik.touched.AFFILITE_ID && Boolean(formik.errors.AFFILITE_ID)}
              helperText={formik.touched.AFFILITE_ID && formik.errors.AFFILITE_ID}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="AFFILITE NAME"
              style={{ width: '100%', marginBottom: '10px' }}
              name="AFFILITE_NAME"
              disabled
              value={formik.values.AFFILITE_NAME}
              onChange={formik.handleChange}
              error={formik.touched.AFFILITE_NAME && Boolean(formik.errors.AFFILITE_NAME)}
              helperText={formik.touched.AFFILITE_NAME && formik.errors.AFFILITE_NAME}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              label="Coupon Code"
              style={{ width: '100%', marginBottom: '10px' }}
              name="COUPON_CODE"
              disabled
              value={formik.values.COUPON_CODE}
              onChange={formik.handleChange}
              error={formik.touched.COUPON_CODE && Boolean(formik.errors.COUPON_CODE)}
              helperText={formik.touched.COUPON_CODE && formik.errors.COUPON_CODE}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="REFERER SOURCE"
              style={{ width: '100%', marginBottom: '10px' }}
              name="REFERER_SOURCE"
              disabled
              value={formik.values.REFERER_SOURCE}
              onChange={formik.handleChange}
              error={
                formik.touched.REFERER_SOURCE && Boolean(formik.errors.REFERER_SOURCE)
              }
              helperText={formik.touched.REFERER_SOURCE && formik.errors.REFERER_SOURCE}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Extra Data"
              style={{ width: '100%', marginBottom: '10px' }}
              name="EXTRA_DATA"
              disabled
              value={formik.values.EXTRA_DATA}
              onChange={formik.handleChange}
              error={formik.touched.EXTRA_DATA && Boolean(formik.errors.EXTRA_DATA)}
              helperText={formik.touched.EXTRA_DATA && formik.errors.EXTRA_DATA}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Order Coin"
              style={{ width: '100%', marginBottom: '10px' }}
              name="ORDER_COIN"
              disabled
              value={formik.values.ORDER_COIN}
              onChange={formik.handleChange}
              error={formik.touched.ORDER_COIN && Boolean(formik.errors.ORDER_COIN)}
              helperText={formik.touched.ORDER_COIN && formik.errors.ORDER_COIN}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              label="Content Id List"
              style={{ width: '100%', marginBottom: '10px' }}
              name="CONTENT_ID_LIST"
              disabled
              value={formik.values.CONTENT_ID_LIST}
              onChange={formik.handleChange}
              error={
                formik.touched.CONTENT_ID_LIST && Boolean(formik.errors.CONTENT_ID_LIST)
              }
              helperText={formik.touched.CONTENT_ID_LIST && formik.errors.CONTENT_ID_LIST}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Is Sub Orders"
              style={{ width: '100%', marginBottom: '10px' }}
              name="IS_SUB_ORDERS"
              disabled
              value={formik.values.IS_SUB_ORDERS}
              onChange={formik.handleChange}
              error={formik.touched.IS_SUB_ORDERS && Boolean(formik.errors.IS_SUB_ORDERS)}
              helperText={formik.touched.IS_SUB_ORDERS && formik.errors.IS_SUB_ORDERS}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Month"
              style={{ width: '100%', marginBottom: '10px' }}
              name="MONTH"
              disabled
              value={formik.values.MONTH}
              onChange={formik.handleChange}
              error={formik.touched.MONTH && Boolean(formik.errors.MONTH)}
              helperText={formik.touched.MONTH && formik.errors.MONTH}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Year"
              style={{ width: '100%', marginBottom: '10px' }}
              name="YEAR"
              disabled
              value={formik.values.YEAR}
              onChange={formik.handleChange}
              error={formik.touched.YEAR && Boolean(formik.errors.YEAR)}
              helperText={formik.touched.YEAR && formik.errors.YEAR}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Renewal Retry"
              style={{ width: '100%', marginBottom: '10px' }}
              name="RENEWAL_RETRY"
              disabled
              value={formik.values.RENEWAL_RETRY}
              onChange={formik.handleChange}
              error={formik.touched.RENEWAL_RETRY && Boolean(formik.errors.RENEWAL_RETRY)}
              helperText={formik.touched.RENEWAL_RETRY && formik.errors.RENEWAL_RETRY}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Prev Order Id"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PREV_ORDER_ID"
              disabled
              value={formik.values.PREV_ORDER_ID}
              onChange={formik.handleChange}
              error={formik.touched.PREV_ORDER_ID && Boolean(formik.errors.PREV_ORDER_ID)}
              helperText={formik.touched.PREV_ORDER_ID && formik.errors.PREV_ORDER_ID}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="PG Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PG_TYPE"
              disabled
              value={formik.values.PG_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.PG_TYPE && Boolean(formik.errors.PG_TYPE)}
              helperText={formik.touched.PG_TYPE && formik.errors.PG_TYPE}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              label="IS Migrated Order"
              style={{ width: '100%', marginBottom: '10px' }}
              name="IS_MIGRATED_ORDER"
              disabled
              color="info"
              value={formik.values.IS_MIGRATED_ORDER}
              onChange={formik.handleChange}
              error={
                formik.touched.IS_MIGRATED_ORDER &&
                Boolean(formik.errors.IS_MIGRATED_ORDER)
              }
              helperText={
                formik.touched.IS_MIGRATED_ORDER && formik.errors.IS_MIGRATED_ORDER
              }
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="IS Free Trial"
              style={{ width: '100%', marginBottom: '10px' }}
              name="IS_FREE_TRIAL"
              disabled
              value={formik.values.IS_FREE_TRIAL}
              onChange={formik.handleChange}
              error={formik.touched.IS_FREE_TRIAL && Boolean(formik.errors.IS_FREE_TRIAL)}
              helperText={formik.touched.IS_FREE_TRIAL && formik.errors.IS_FREE_TRIAL}
            />
          </Grid> */}
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/order/orders');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default OrderForm;
