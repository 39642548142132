import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from 'react-feather';

const CustomerListToolbar = (
  {
    filteredData,
    addOnClick,
    addButtonName,
    searchBy
  }
) => {
  const [input, setInput] = useState('');

  const handleChange = (e, value) => {
    const newValue = e.target.value;
    setInput(newValue);
    filteredData(value);
    // console.log('data1',filteredData);
  };

  useEffect(() => {
    console.log('addbutton', addButtonName);
    // console.log('filteredData',filteredData);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button color="inherit">
          Import
        </Button>
        <Button color="inherit" sx={{ mx: 1 }}>
          Export
        </Button>
        <Button
          color="inherit"
          variant="contained"
          onClick={() => {
            addOnClick();
          }}
        >
          {addButtonName}
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                onChange={(e) => handleChange(e, e.target.value)}
                value={input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                        {searchBy}
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder={searchBy}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

CustomerListToolbar.propTypes = {
  filteredData: PropTypes.func,
  addOnClick: PropTypes.func,
  addButtonName: PropTypes.string,
  searchBy: PropTypes.string
};

export default CustomerListToolbar;
