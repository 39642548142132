/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
    Grid,
    Paper,
    TextField,
    Radio,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormHelperText,
    Typography,
    Stack,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import DatePicker from '@mui/lab/DatePicker';
import { orderMisReport1Download, orderMisReport2Download, orderMisReport3Download } from 'src/services/report/vendorReportApis';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
    // vendor: yup.string().required('Vendor is required'),
    start_date: yup.string().required('Start Date is required'),
    end_date: yup.string().required('End Date is required')
});

const OrderMisReportForm = () => {
    const [snackBar, setSnackBar] = useState({ open: false, message: '' });
    const setSnackBarOpen = (val, msg) => {
        if (snackBar.open === true) {
            setSnackBar({ open: false, message: msg });
        } else {
            setSnackBar({ open: val, message: msg });
        }
    };

    const navigate = useNavigate();
    const handleDownload2 = (value) => {
        validateForm();
        // if (dayjs().date() === 5) {
            const obj = {
                ...value,
                start_date: dayjs(value.start_date).unix(),
                end_date: dayjs(value.end_date).unix(),
            };
            const downloadData2 = validateEndDate(value.start_date, value.end_date)
            if (downloadData2 == true) {
                orderMisReport2Download({ ...obj })
                    .then((res) => {
                        setSnackBarOpen(true, 'Report Downloaded.');
                        setSnackBarOpen(false, '');
                    })
                    .catch((error) => {
                        setSnackBarOpen(true, error);
                    });
            } else {
                // Invalid: More than one month after start date
                setSnackBarOpen(true, 'End Date must be greater than Start Date and not more than one month.');
            }
        // } else {
        //     setSnackBarOpen(true, 'Download is only available on the 5th of the every month.');
        // }
    };

    const handleDownload3 = (value) => {
        validateForm();
        // if (dayjs().date() === 5) {
            const obj = {
                ...value,
                start_date: dayjs(value.start_date).unix(),
                end_date: dayjs(value.end_date).unix(),
            };
            const downloadData3 = validateEndDate(value.start_date, value.end_date)
            if (downloadData3 == true) {
                orderMisReport3Download({ ...obj })
                    .then((res) => {
                        setSnackBarOpen(true, 'Report Downloaded.');
                        setSnackBarOpen(false, '');
                    })
                    .catch((error) => {
                        setSnackBarOpen(true, error);
                    });
            } else {
                // Invalid: More than one month after start date
                setSnackBarOpen(true, 'End Date must be greater than Start Date and not more than one month.');
            }
        // } else {
        //     setSnackBarOpen(true, 'Download is only available on the 5th of the every month.');
        // }
    };

    const validateEndDate = (startDate, endDate) => {
        // const start = dayjs(startDate);
        // const end = dayjs(endDate);

        // const differenceInDays = end.diff(start, 'day');

        // return differenceInDays > 0 && differenceInDays <= 30;

        const start = dayjs(startDate); // Parse the start date using dayjs
        const end = dayjs(endDate); // Parse the end date using dayjs
    
        // Check if the end date is after the start date and within one month
        return end.isAfter(start) && end.isBefore(start.add(1, 'month').add(1, 'day'));
    
    };

    const validateForm = () => {
        formik.validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                formik.handleSubmit();
            } else {
                setSnackBarOpen(true, 'Please fill out all required fields correctly.');
            }
        });
    };
    const formik = useFormik({
        initialValues: {
            // vendor: '',
            start_date: '',
            end_date: ''
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (value) => {
            // let addedON = Math.floor(Date.now() / 1000);
            // let addedBy = 1;
            // if (dayjs().date() === 5) {
                const obj = {
                    ...value,
                    start_date: dayjs(value.start_date).unix(),
                    end_date: dayjs(value.end_date).unix(),
                };
                const data = validateEndDate(value.start_date, value.end_date)
                if (data == true) {
                    // Valid: Exactly one month after start date
                    orderMisReport1Download({ ...obj })
                        .then((res) => {
                            console.log('res ::', res);
                            setSnackBarOpen(true, 'Report Downloaded.');
                        })
                        .catch((error) => {
                            setSnackBarOpen(true, error);
                        });
                } else {
                    // Invalid: More than one month after start date
                    setSnackBarOpen(true, 'End Date must be greater than Start Date and not more than one Month.');
                }
            // } else {
            //     setSnackBarOpen(true, 'Download is only available on the 5th of the every month.');
            // }
        }
    });

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                margin: 2
            }}
        >
            <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
            <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
                {'Order MIS Report'}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={formik.touched.vendor && formik.errors.vendor}>
              <FormLabel component="legend">Select Vendor</FormLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                name="vendor"
                value={formik.values.vendor}
                onChange={formik.handleChange}
              >
                <MenuItem value={'meghbela'}>{'Meghbela'}</MenuItem>
                <MenuItem value={'ani'}>{'ANI'}</MenuItem>
                <MenuItem value={'tatasky'}>{'Tatasky'}</MenuItem>
                <MenuItem value={'playbox'}>{'Playbox'}</MenuItem>
              </Select>
              <FormHelperText>
                {formik.touched.vendor && formik.errors.vendor}
              </FormHelperText>
            </FormControl>
          </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextField
                            label=""
                            style={{ width: '100%', marginBottom: '10px' }}
                            name="start_date"
                            type="datetime-local"
                            value={formik.values.start_date}
                            onChange={formik.handleChange}
                            error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                            helperText={formik.touched.start_date && formik.errors.start_date}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextField
                            label=""
                            style={{ width: '100%', marginBottom: '10px' }}
                            name="end_date"
                            type="datetime-local"
                            value={formik.values.end_date}
                            onChange={formik.handleChange}
                            // onChange={(event) => {
                            //     formik.handleChange(event);
                            //     const startDate = new Date(formik.values.start_date);
                            //     const endDate = new Date(event.target.value);
                            //     if (startDate && !isNaN(startDate) && endDate && !isNaN(endDate)) {
                            //       const oneMonthAfterStartDate = new Date(startDate);
                            //       oneMonthAfterStartDate.setMonth(oneMonthAfterStartDate.getMonth() + 1);
                            //       if (endDate < oneMonthAfterStartDate) {
                            //         formik.setFieldValue('end_date', oneMonthAfterStartDate.toISOString().slice(0, -8));
                            //       }
                            //     }
                            //   }}
                            error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                            helperText={
                                formik.touched.end_date &&
                                    formik.errors.end_date ? formik.errors.end_date : 'Select a date one month after the start date.'
                            } max={formik.values.start_date}
                        />
                    </Grid>
                </Grid>

                <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
                <Stack direction="row" spacing={2} justifyContent="right">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            navigate('/app/dashboard');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        Download 1
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleDownload2(formik.values)}>
                        Download 2
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleDownload3(formik.values)}>
                        Download 3
                    </Button>
                </Stack>
            </form>
        </Paper>
    );
};

export default OrderMisReportForm;
