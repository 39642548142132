import OrderMisReportForm from 'src/components/report/order/OrderMisReportForm';
import { Helmet } from 'react-helmet';

const OrderMisReport = () => (
  <>
    <Helmet>
      <title> Order MIS Report </title>
    </Helmet>
    <div>
      <OrderMisReportForm />
    </div>
  </>
);

export default OrderMisReport;
