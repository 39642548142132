import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ProductMasterList from 'src/components/product/ProductMasterList';
// import CustomerListToolbarPlanMaster from 'src/components/customer/CustomerListToolbarPlanMaster';
import CustomNotifier from 'src/customComponents/Notifier';

import { fetchProductMasterData, fetchSearchData } from 'src/services/product/product';
import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
import { useNavigate } from 'react-router-dom';

const ProductMaster = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [input, setInput] = useState('');

  const [snackBar, setSnackBar] = useState({ open: false, message: '' });

  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const filteredData = (data) => {
    setInput(data);
    console.log('data', data);
    if (data === '' || null || undefined) setIsLoading(true);
    return data;
  };

  const dataLimit = (view) => {
    setIsLoading(true);
    setLimit(view);
  };

  const pageNumber = (pageNum) => {
    setIsLoading(true);
    setPage(pageNum);
  };

  const productMasterData = async (Page, Limit) => {
    fetchProductMasterData(Page, Limit)
      .then((res) => {
        setPagination(res?.pagination);
        setTableData(res?.tableData);
        setSnackBarOpen(true, res.message);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackBarOpen(true, error);
      });
  };

  const searchData = async (Page, Limit, Input) => {
    fetchSearchData(Page, Limit, Input)
      .then((res) => {
        setPagination(res?.pagination);
        setTableData(res?.tableData);
        setSnackBarOpen(true, res.message);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackBarOpen(true, error);
      });
  };

  useEffect(() => {
    if (isLoading && input.length <= 0) {
      productMasterData(page, limit);
    }

    if (input && input.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        console.log('input', input, 'typeod', typeof input);
        searchData(page, limit, input);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }

    return true;
  }, [isLoading, limit, page, input]);

  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar
            addOnClick={() => {
              navigate('/product/add');
            }}
            addButtonName="ADD PRODUCT"
            searchBy="Product Name"
            filteredData={filteredData}
          />
          <Box sx={{ pt: 3 }}>
            <ProductMasterList
              isLoading={isLoading}
              pageNumber={pageNumber}
              dataLimit={dataLimit}
              tableData={tableData}
              pagination={pagination}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProductMaster;
