import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  Select,
  FormHelperText,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { useFormik } from 'formik';

const BannerInfoForm = () => {
  const formik = useFormik({
    initialValues: {
      ProductId: '',
      countryCode: '',
      PlatformId: '',
      sequenceNo: '',
      sliderHeading: '',
      status: '',
      Patym: '',
      StartDate: '',
      EndDate: ''
    },
    // validationSchema: validationSchema,
    onSubmit: () => {}
  });
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Product ID
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Product ID"
                name="ProductId"
                value={formik.values.ProductId}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <TextField
              id="outlined-disabled"
              label="country Code"
              name="countryCode"
              style={{ width: '100%', marginBottom: '10px' }}
              value={formik.values.countryCode}
              onChange={formik.handleChange}
              error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
              helperText={formik.touched.countryCode && formik.errors.countryCode}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Platform Id
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Platform Id"
                name="PlatformId"
                value={formik.values.PlatformId}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <TextField
              id="outlined-disabled"
              label="sequence No"
              name="sequenceNo"
              style={{ width: '100%', marginBottom: '10px' }}
              value={formik.values.sequenceNo}
              onChange={formik.handleChange}
              error={formik.touched.sequenceNo && Boolean(formik.errors.sequenceNo)}
              helperText={formik.touched.sequenceNo && formik.errors.sequenceNo}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <TextField
              id="outlined-disabled"
              label="slider Heading"
              name="sliderHeading"
              style={{ width: '100%', marginBottom: '10px' }}
              value={formik.values.sliderHeading}
              onChange={formik.handleChange}
              error={formik.touched.sliderHeading && Boolean(formik.errors.sliderHeading)}
              helperText={formik.touched.sliderHeading && formik.errors.sliderHeading}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="slider Desc"
              style={{ width: '100%', marginBottom: '10px', height: '55px' }}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl component="fieldset" error={formik.touched.profile1 && Boolean(formik.errors.profile1)}>
              <FormLabel component="legend">Upload a image</FormLabel>

              <input
                id="file"
                name="profile1"
                type="file"
                onChange={(event) => {
                  const { files } = event.target;
                  const myFiles = Array.from(files);
                  formik.setFieldValue('profile', myFiles[0]);
                  console.log('myfile', myFiles[0]);
                }}

              />
              <FormHelperText>{formik.touched.profile1 && formik.errors.profile1}</FormHelperText>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl component="fieldset" error={formik.touched.profile2 && Boolean(formik.errors.profile2)}>
              <FormLabel component="legend">Upload a image</FormLabel>

              <input
                id="file"
                name="profile2"
                type="file"
                onChange={(event) => {
                  const { files } = event.target;
                  const myFiles = Array.from(files);
                  formik.setFieldValue('profile', myFiles[0]);
                  console.log('myfile', myFiles[0]);
                }}

              />
              <FormHelperText>{formik.touched.profile2 && formik.errors.profile2}</FormHelperText>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl component="fieldset" error={formik.touched.status && Boolean(formik.errors.status)}>
              <FormLabel component="legend">Status</FormLabel>

              <RadioGroup
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="1" control={<Radio />} label="Active" />
                  <FormControlLabel value="0" control={<Radio />} label="De-Active" />
                </div>
              </RadioGroup>
              <FormHelperText>{formik.touched.status && formik.errors.status}</FormHelperText>
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>

            <FormControl fullWidth>

              <TextField
                // error
                // required
                id="outlined-required"
                label="Start Date"
                name="StartDate"
                type="date"
                focused
                color="grey"
                // defaultValue="Plan Country"
                value={formik.values.StartDate}
                onChange={formik.handleChange}
                error={formik.touched.StartDate && Boolean(formik.errors.StartDate)}
                helperText={formik.touched.StartDate && formik.errors.StartDate}

              />
            </FormControl>

          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <TextField
                // error
                // required
                id="outlined-required"
                label="End Date"
                name="EndDate"
                type="date"
                focused
                color="grey"
                // defaultValue="Plan Country"
                value={formik.values.EndDate}
                onChange={formik.handleChange}
                error={formik.touched.EndDate && Boolean(formik.errors.EndDate)}
                helperText={formik.touched.EndDate && formik.errors.EndDate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Banner Sub Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select"
                //   value={age}
                label="Banner Sub Type"
                name="bannerSubType"
                value={formik.values.bannerSubType}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

          </Grid>

        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <div style={{ textAlign: 'right' }}>
          <Button type="submit">
            Go
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default BannerInfoForm;
