import React from 'react';
import {
  Container,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextareaAutosize,
  FormHelperText
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchemaValue = yup.object({
  service: yup.string().required('service required'),
  campaignName: yup.string().required('Campaign required'),
  paymentCardDetails: yup.string().required('select Payment Card Dertails'),
  renewal: yup.string().required('select Renewal'),
  paymentMethode: yup.string().required('select Payment Methode'),
  radioGroup: yup.string().required('elect Type'),
  subcriptionPlan: yup.number().required('select subscription plan'),
  paymentPlanId: yup.number().required('select payment plan id'),
  showads: yup.number().required('Select ads'),
  payments: yup.number().required('Select Payments'),
  downloads: yup.number().required('Select Downloads'),
  couponUsage: yup.number().required('Select Coupon usage'),
  couponToGenerate: yup.string().required('coupon required'),
  startDate: yup.date().required('Start date required'),
  endDate: yup.date().required('End date required'),
  message: yup.string().required('message required')
});

const AddCampaignForm = () => {
  const formik = useFormik({
    initialValues: {
      service: '',
      campaignName: '',
      paymentCardDetails: '',
      renewal: '',
      paymentMethode: '',
      radioGroup: '',
      subcriptionPlan: '',
      paymentPlanId: '',
      showads: '',
      payments: '',
      downloads: '',
      couponUsage: '',
      couponToGenerate: '',
      startDate: '',
      endDate: '',
      message: '',
      platformCode: ''

    },
    validationSchema: validationSchemaValue,
    onSubmit: () => {
    }
  });

  return (
    <Container style={{ marginTop: '1rem' }}>
      <h3>Add New Coupons</h3>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          margin: 2
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.service && Boolean(formik.errors.service)}>
              <InputLabel id="demo-simple-select-required-label">
                Service
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Service"
                name="service"
                value={formik.values.service}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.service && formik.errors.service}</FormHelperText>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>

              <TextField
                id="outlined-disabled"
                label="Campaign Name"
                name="campaignName"
                style={{ width: '100%', marginBottom: '10px' }}
                value={formik.values.campaignName}
                onChange={formik.handleChange}
                error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
                helperText={formik.touched.campaignName && formik.errors.campaignName}
              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.paymentCardDetails && Boolean(formik.errors.paymentCardDetails)}>
              <InputLabel id="demo-simple-select-required-label">
                Payment (Card) Details
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Payment (Card) Details"
                name="paymentCardDetails"
                value={formik.values.paymentCardDetails}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.paymentCardDetails && formik.errors.paymentCardDetails}</FormHelperText>

            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.renewal && Boolean(formik.errors.renewal)}>
              <InputLabel id="demo-simple-select-required-label">
                Renewal
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Renewal"
                name="renewal"
                value={formik.values.renewal}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.renewal && formik.errors.renewal}</FormHelperText>

            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.paymentMethode && Boolean(formik.errors.paymentMethode)}>
              <InputLabel id="demo-simple-select-required-label">
                Payment Methode
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Payment Methode"
                name="PaymentMethode"
                value={formik.values.paymentMethode}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.paymentMethode && formik.errors.paymentMethode}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl component="fieldset" error={formik.touched.radioGroup && Boolean(formik.errors.radioGroup)}>
              <FormLabel component="legend">Select Type</FormLabel>

              <RadioGroup
                name="radioGroup"
                value={formik.values.radioGroup}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="Subcription" control={<Radio />} label="Subcription" />
                  <FormControlLabel value="Rent" control={<Radio />} label="Rent" />
                </div>
              </RadioGroup>
              <FormHelperText>{formik.touched.radioGroup && formik.errors.radioGroup}</FormHelperText>
            </FormControl>
          </Box>

          {/* <FormControl sx={{ m: 1, minWidth: 640, maxWidth: 400 }} error={formik.touched.subcriptionPlan && Boolean(formik.errors.subcriptionPlan)}>
             <InputLabel id="demo-simple-select-required-label"  >
                Subcription Plan
              </InputLabel>
              <Select
                        multiple
                        Subcription Plan
                value={personName}
                // @ts-ignore Typings are not considering `native`
                onChange={handleChangeMultiple}
                label="Subcription Plan"
                inputProps={{
                  id: 'select-multiple-native',
                }}
              >
                {names.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormHelperText>{formik.touched.subcriptionPlan && formik.errors.subcriptionPlan}</FormHelperText>
            </FormControl> */}

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.subcriptionPlan && Boolean(formik.errors.subcriptionPlan)}>
              <InputLabel id="demo-simple-select-required-label">
                Subcription Plan
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Subcription Plan"
                name="subcriptionPlan"
                value={formik.values.subcriptionPlan}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.subcriptionPlan && formik.errors.subcriptionPlan}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormLabel component="legend">
              Get Plan Details ID:
              <a href="#">Click me</a>
            </FormLabel>

          </Box>

          {/* <FormControl sx={{ m: 1, minWidth: 640, maxWidth: 400 }} error={formik.touched.paymentPlanId && Boolean(formik.errors.paymentPlanId)}>
             <InputLabel id="demo-simple-select-required-label" >
                Payment Plan Id
              </InputLabel>
              <Select
                        multiple
                        Payment Plan Id
                        value={personName}
                // @ts-ignore Typings are not considering `native`
                onChange={handleChangeMultiple}
                label="Payment Plan Id"
                inputProps={{
                  id: 'select-multiple-native',
                }}
              >
                {names.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormHelperText>{formik.touched.paymentPlanId && formik.errors.paymentPlanId}</FormHelperText>
            </FormControl> */}

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.paymentPlanId && Boolean(formik.errors.paymentPlanId)}>
              <InputLabel id="demo-simple-select-required-label">
                Payment Plan Id
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Payment Plan Id"
                name="paymentPlanId"
                value={formik.values.paymentPlanId}
                onChange={formik.handleChange}
              //   onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.paymentPlanId && formik.errors.paymentPlanId}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.showads && Boolean(formik.errors.showads)}>
              <InputLabel id="demo-simple-select-required-label">
                Show Ads
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Show Ads"
                name="showads"
                value={formik.values.showads}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.showads && formik.errors.showads}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.payments && Boolean(formik.errors.payments)}>
              <InputLabel id="demo-simple-select-required-label">
                Payment
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Payments"
                name="payments"
                value={formik.values.payments}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.payments && formik.errors.payments}</FormHelperText>

            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.downloads && Boolean(formik.errors.downloads)}>
              <InputLabel id="demo-simple-select-required-label">
                Downloads
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Downloads"
                name="downloads"
                value={formik.values.downloads}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.downloads && formik.errors.downloads}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.couponUsage && Boolean(formik.errors.couponUsage)}>
              <InputLabel id="demo-simple-select-required-label">
                COUPON Usage
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                label="COUPON Usage"
                name="couponUsage"
                value={formik.values.couponUsage}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.couponUsage && formik.errors.couponUsage}</FormHelperText>
            </FormControl>
          </Box>

          {/* <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
                <FormControl fullWidth >
                  <TextField
                    // error
                    // required
                    id="outlined-required"
                    label="No. Of Coupon to Generate"
                    name='CouponToGenerate'
                    // defaultValue="Plan Country"
                    value={formik.values.couponToGenerate}
                    onChange={formik.handleChange}
                    error={formik.touched.couponToGenerate && Boolean(formik.errors.couponToGenerate)}
                    helperText={formik.touched.couponToGenerate && formik.errors.couponToGenerate}
                          />
                </FormControl>
              </Box> */}

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth>

              <TextField
                id="outlined-disabled"
                label="No. Of Coupon to Generate"
                name="couponToGenerate"
                style={{ width: '100%', marginBottom: '10px' }}
                value={formik.values.couponToGenerate}
                onChange={formik.handleChange}
                error={formik.touched.couponToGenerate && Boolean(formik.errors.couponToGenerate)}
                helperText={formik.touched.couponToGenerate && formik.errors.couponToGenerate}
              />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.startDate && Boolean(formik.errors.startDate)}>
              <FormLabel component="legend">Start Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                name="startDate"
                type="date"
                value={formik.values.startDate}
                onChange={formik.handleChange}

              />
              <FormHelperText>{formik.touched.startDate && formik.errors.startDate}</FormHelperText>

            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            <FormControl fullWidth error={formik.touched.endDate && Boolean(formik.errors.endDate)}>
              <FormLabel component="legend">End Date</FormLabel>
              <TextField
                // error
                // required
                id="outlined-required"
                name="endDate"
                type="date"
                value={formik.values.endDate}
                onChange={formik.handleChange}
              />
              <FormHelperText>{formik.touched.endDate && formik.errors.endDate}</FormHelperText>

            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, maxWidth: 640, margin: 1 }}>
            {/* <FormLabel component="legend">Welcome message after Redemption</FormLabel> */}
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Welcome message after Redemption"
              style={{ width: 640, height: 100 }}
            />

          </Box>
          <Box sx={{ minWidth: 120, maxWidth: 140, margin: 1 }}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Paper>

    </Container>
  );
};

export default AddCampaignForm;
