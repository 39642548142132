/* eslint-disable import/order */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
// import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
// import ProductList from 'src/pages/ProductList';
// import Register from 'src/pages/Register';
// import Settings from 'src/pages/Settings';
import PlanDetails from 'src/pages/plans/details/PlanDetails';
import AddEditPlanDetails from './pages/plans/details/AddEditPlanDetails';

import PlanMaster from 'src/pages/plans/master/PlanMaster';
import AddEditPlanMaster from './pages/plans/master/AddEditPlanMaster';

import PlanSequence from 'src/pages/plans/sequence/PlanSequence';
import AddEditPlanSequence from './pages/plans/sequence/AddEditPlanSequence';

import PaymentMaster from 'src/pages/payments/master/PaymentMaster';
import AddEditPaymentMaster from './components/payments/master/AddEditPaymentMaster';

import PaymentMapping from 'src/pages/payments/mapping/PaymentMapping';
import AddEditPaymentMapping from './components/payments/mapping/AddEditPaymentMapping';

import PaymentSequence from 'src/pages/payments/sequence/PaymentSequence';
import AddEditPaymentSequence from './components/payments/sequence/AddEditPaymentSequence';

import ProductMaster from 'src/pages/product/ProductMaster';
import AddEditProductMaster from './pages/product/AddEditProductMaster';

import PlatformMaster from 'src/pages/platfrom/PlatformMaster';
import AddEditPlatformMaster from './pages/platfrom/AddEditPlatformMaster';

// import Orders from 'src/pages/user/orders/Orders';
import Orders from 'src/pages/order/Orders';
import EditViewOrder from 'src/pages/order/EditViewOrder';

import AssignSubscription from 'src/pages/order/AssignSubscription';

// import OrderDetails from 'src/pages/user/orders/OrderDetails';
// import UsersOrder from './components/user/UsersOrder';

import Register from './pages/adminUser/Register';
import ManageRights from './pages/adminUser/manageRights/ManageRights';
import UserListing from './pages/adminUser/userListing/UserListing';
import AddCoupon from './pages/couponManagement/addCoupon/AddCoupon';

import AddBillingReports from './pages/billing/billingReports/AddBillingReports';
import VendorReport from './pages/report/vendor/VendorReport';

import AddCampaignForm from './components/couponManagement/addCoupon/AddCampaignForm';
import BannerInfoForm from './components/banner/BannerInfoForm';
import OrderReport from './pages/report/order/OrderReport';
import MISOrderReport from './pages/report/order/OrederMisReport';

const isLoggedIn = true;

const routes = [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'account', element: <Account /> },
      // { path: 'plandetails', element: <PlanDetails /> },
      // { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'products', element: <ProductList /> },
      // { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'plan',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'details', element: <PlanDetails /> },
      { path: 'master', element: <PlanMaster /> },
      { path: 'sequence', element: <PlanSequence /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'master/add', element: <AddEditPlanMaster /> },
      { path: 'master/edit/:planMastId', element: <AddEditPlanMaster /> },
      { path: 'sequence/add', element: <AddEditPlanSequence /> },
      { path: 'sequence/edit/:planSeqId', element: <AddEditPlanSequence /> },
      { path: 'details/add', element: <AddEditPlanDetails /> },
      { path: 'details/edit/:planDetailId', element: <AddEditPlanDetails /> }
    ]
  },
  {
    path: 'payment',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'master', element: <PaymentMaster /> },
      { path: 'master/add', element: <AddEditPaymentMaster /> },
      { path: 'master/edit/:payMastId', element: <AddEditPaymentMaster /> },
      { path: 'mapping', element: <PaymentMapping /> },
      { path: 'mapping/add', element: <AddEditPaymentMapping /> },
      { path: 'mapping/edit/:payMapId', element: <AddEditPaymentMapping /> },
      { path: 'sequence', element: <PaymentSequence /> },
      { path: 'sequence/add', element: <AddEditPaymentSequence /> },
      { path: 'sequence/edit/:paymentSeqId', element: <AddEditPaymentSequence /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'admin_user',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'register', element: <Register /> },
      { path: 'manage_rights', element: <ManageRights /> },
      { path: 'user_listing', element: <UserListing /> }
    ]
  },
  {
    path: 'coupon_management',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'add_coupon', element: <AddCoupon /> },
      { path: 'add_campaign', element: <AddCampaignForm /> }
    ]
  },
  {
    path: 'order',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'orders', element: <Orders /> },
      { path: 'edit/:ordID', element: <EditViewOrder /> },
      // { path: 'order/details/:orderid', element: <OrderDetails /> },
      { path: 'assign-subscription', element: <AssignSubscription /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'report',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'reports', element: <AddBillingReports /> },
      { path: 'vendor', element: <VendorReport /> },
      { path: 'order', element: <OrderReport /> },
      { path: 'ordermis', element: <MISOrderReport /> },
    ]
  },
  {
    path: 'product',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'products', element: <ProductMaster /> },
      { path: 'add', element: <AddEditProductMaster /> },
      { path: 'edit/:prodId', element: <AddEditProductMaster /> }
    ]
  },
  {
    path: 'platform',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [
      { path: 'platforms', element: <PlatformMaster /> },
      { path: 'add', element: <AddEditPlatformMaster /> },
      { path: 'edit/:platformId', element: <AddEditPlatformMaster /> }
    ]
  },
  {
    path: 'banner',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />, // <DashboardLayout />
    children: [{ path: 'banner_info', element: <BannerInfoForm /> }]
  },
  {
    path: '/',
    element: <MainLayout />, // <MainLayout />
    children: [
      { path: 'login', element: <Login /> },
      // { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
  // {
  //   path: 'error',
  //   element: <MainLayout />, // <MainLayout />
  //   children: [
  //     { path: '404', element: <NotFound /> },
  //     { path: '*', element: <Navigate to="/error/404" /> }
  //   ]
  // }
];

export default routes;
