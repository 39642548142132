/* eslint-disable */
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Collapse,
  Paper,
  TextField,
  TableContainer,
  TableCell,
  Stack,
  Tooltip,
  Chip
} from '@material-ui/core';
import { TableLoading } from 'src/customComponents/loadingScreen';
import {
  StyledTableCell,
  StyledChip,
  useStyles
} from 'src/components/common/tableStyles';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { red } from '@mui/material/colors';
import {
  ExpandMore as ExpandMoreIcon,
  AccountCircle,
  ExpandMore,
  Edit,
  Delete,
  RemoveCircle,
  RemoveRedEyeRounded,
  CancelRounded
} from '@material-ui/icons';

import { statusCheck } from 'src/utils/statusCheck';
import { useState } from 'react';
import { Grid } from 'react-feather';
import { styled } from '@material-ui/styles';
import DialogBox from '../common/Dialog';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { updateOrderApi } from 'src/services/order/orderApi';
import {
  checkRenewalStatusByOrderIdPaymentId,
  mandateRevoke
} from 'src/services/renewal/autoRenewalApi';
import rabbitMqEventReceiver from 'src/utils/rabbbitMQReceiverApi.js';
import delay from 'src/utils/delay';
import CustomNotifier from 'src/customComponents/Notifier';

const OrderList = ({
  isLoading,
  pageNumber,
  dataLimit,
  tableData,
  userDetails,
  pagination,
  ...rest
}) => {
  // console.log('userDetails::', userDetails, typeof userDetails);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };
  const navigate = useNavigate();
  const [unsubscribeDialog, setUnsubscribeDialog] = useState({
    isOpen: false,
    dialogTitle: '',
    dialogMsg: '',
    dialogBtnName: '',
    showDialogBtn: true,
    dialogComponent: <></>,
    dialogSubmitMethod: () => {},
    dialogClearMethod: () => {}
  });

  const handleChange = (event, value) => pageNumber(value);
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleOrderDetails = (e, orderId) => {
    e.preventDefault();
    navigate(`/user/order/details/${orderId}`);
  };

  const handleStopRenewalOrder = async (orderData) => {
    console.log('handleStopRenewalOrder');
    let revokeRes = await mandateRevoke({
      orderId: orderData?.ORDER_ID,
      paymentId: orderData?.PAYMENT_ID
    });
    // console.log('revokeRes:', revokeRes);
    if (revokeRes?.success === true) {
      setSnackBarOpen(true, revokeRes?.message);
      // await delay(1500);
      // navigate(`/order`);
      // navigate(`/order/orders`);
    }
  };
  const handleDeactivateOrder = async (orderData) => {
    // console.log('handleDeactivateOrder');
    let updateOrderRes = await updateOrderApi({
      ID: orderData?.ID,
      ORDER_STATUS: 3,
      VALIDITY_STATUS: 0,
      REMARK: 'closed by admin',
      IS_SEND_MIX_PANEL_EVENT_DEACTIVATE: 1
    });
    // console.log('updateOrderRes', updateOrderRes);
    if (updateOrderRes?.success === true && updateOrderRes?.statusCode === 200) {
      setSnackBarOpen(true, 'Order Deactivated.');
      await delay(1500);
      navigate(`/order`);
      navigate(`/order/orders`);
    }
  };

  const clearDialogState = () => {
    setUnsubscribeDialog((prevState) => ({
      ...prevState,
      isOpen: false,
      dialogTitle: '',
      dialogMsg: '',
      dialogBtnName: '',
      showDialogBtn: true,
      dialogComponent: <></>,
      dialogSubmitMethod: () => {},
      dialogClearMethod: () => {}
    }));
  };

  const classes = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  return (
    <div className={classes.root}>
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      {Object?.keys(userDetails)?.length > 0 && (
        <Card>
          <CardHeader
            style={{
              backgroundColor: '#000000',
              color: 'white'
            }}
            avatar={<AccountCircle fontSize="large" />}
            action={
              <ExpandMore
                fontSize="large"
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            title="User Info"
          />
          <CardContent>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">User ID</TableCell>
                  <TableCell>{userDetails.USER_ID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Username</TableCell>
                  <TableCell>{userDetails.USER_NAME}</TableCell>
                </TableRow>
                {expanded === true ? (
                  <>
                    <TableRow>
                      <TableCell variant="head">First Name</TableCell>
                      <TableCell>{userDetails.FIRST_NAME}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Last Name</TableCell>
                      <TableCell>{userDetails.LAST_NAME}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Mobile No</TableCell>
                      <TableCell>{userDetails.MOBILENO}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Refferer</TableCell>
                      <TableCell>{userDetails.REFFERER}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Affiliate ID</TableCell>
                      <TableCell>{userDetails.AFFILIATE_ID}</TableCell>
                    </TableRow>
                  </>
                ) : (
                  ''
                )}
              </TableBody>
            </Table>
            {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <caption>A basic table example with a caption</caption>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse> */}
          </CardContent>
        </Card>
      )}
      {Object.keys(tableData)?.length > 0 && (
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Action</StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Order Id</StyledTableCell>
                    <StyledTableCell>Identity</StyledTableCell>
                    <StyledTableCell>Order Date</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>Payment Id</StyledTableCell>
                    <StyledTableCell>Payment Name</StyledTableCell>
                    <StyledTableCell>Plan Id</StyledTableCell>
                    <StyledTableCell>Plan Name</StyledTableCell>
                    <StyledTableCell>Order Status</StyledTableCell>
                    {/* <StyledTableCell>Is Auto Renewal ?</StyledTableCell> */}
                    <StyledTableCell>Plan Amount</StyledTableCell>
                    <StyledTableCell>TXN Amount</StyledTableCell>
                    <StyledTableCell>Event Type</StyledTableCell>
                    <StyledTableCell>Coupon Code</StyledTableCell>
                    <StyledTableCell>Product Name</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* isLoading */}
                  {false ? (
                    // <TableRow>
                    // <td colSpan="100%" style={{ textAlign: 'center' }}>
                    <TableLoading rows={14} columns={10} />
                  ) : (
                    // </td>
                    // </TableRow>
                    tableData.map((data) => (
                      <TableRow hover key={data.ID}>
                        <StyledTableCell>
                          <Stack direction="col" spacing={2}>
                            <IconButton
                              color="default"
                              aria-label="edit"
                              onClick={(e) => {
                                if (data?.ID) {
                                  navigate(`/order/edit/${data?.ID}`);
                                }
                              }}
                            >
                              {/* <Edit /> */}
                              <Tooltip title="View/Edit Order">
                                <RemoveRedEyeRounded />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              color="error"
                              aria-label="del"
                              disabled={data?.ORDER_STATUS !== 1 ? true : false}
                              onClick={async (e) => {
                                if (data?.ORDER_ID) {
                                  //call check renewal status
                                  let checkRenewalRes =
                                    await checkRenewalStatusByOrderIdPaymentId(
                                      data?.ORDER_ID,
                                      data?.PAYMENT_ID
                                    );
                                  // console.log(
                                  //   'checkRenewalRes ',
                                  //   checkRenewalRes,
                                  //   checkRenewalRes?.data,
                                  //   checkRenewalRes?.data.length,
                                  //   checkRenewalRes?.data[0]['AUTO_RENEWAL']
                                  // );
                                  // call revoke mandate api
                                  if (
                                    checkRenewalRes?.data?.length > 0 &&
                                    checkRenewalRes?.statusCode === 200
                                  ) {
                                    //set componet
                                    let customComponent = () => {
                                      return (
                                        <Grid2
                                          container
                                          rowSpacing={1}
                                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        >
                                          <Grid2 xs={3}>
                                            <Item>
                                              <strong>Order ID</strong>
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              <strong>Iedentity</strong>
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              <strong>Payment ID</strong>
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              <strong>Auto Renewal?</strong>
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              {checkRenewalRes?.data[0]['ORDER_ID']}
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              {checkRenewalRes?.data[0]['IDENTITY']}
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            <Item>
                                              {checkRenewalRes?.data[0]['paymentId']}
                                            </Item>
                                          </Grid2>
                                          <Grid2 xs={3}>
                                            {checkRenewalRes?.data[0]['AUTO_RENEWAL'] ===
                                            1 ? (
                                              <Item>
                                                <Chip
                                                  label="Yes"
                                                  size="small"
                                                  color={'success'}
                                                />
                                              </Item>
                                            ) : (
                                              <Item>
                                                <Chip
                                                  label="No"
                                                  size="small"
                                                  color={'default'}
                                                />
                                              </Item>
                                            )}
                                          </Grid2>
                                        </Grid2>
                                      );
                                    };
                                    setUnsubscribeDialog((prevState) => ({
                                      ...prevState,
                                      isOpen: true,
                                      dialogTitle: 'Unsubscribe',
                                      dialogMsg: ``,
                                      dialogBtnName: 'Stop',
                                      showDialogBtn:
                                        checkRenewalRes?.data[0]['AUTO_RENEWAL'] === 1
                                          ? true
                                          : false,
                                      dialogComponent: customComponent,

                                      dialogSubmitMethod: () => {
                                        handleStopRenewalOrder(data);
                                      },
                                      dialogClearMethod: clearDialogState
                                    }));
                                  }
                                }
                              }}
                            >
                              <Tooltip title="Check/Stop Renewal">
                                <RemoveCircle />
                              </Tooltip>
                            </IconButton>

                            {/* new Date(data?.END_DATE).getTime() / 1000 <
                                  Math.floor(Date.now() / 1000) && data.ORDER_STATUS !== 1 */}
                            <IconButton
                              color="error"
                              aria-label="del"
                              disabled={data.ORDER_STATUS !== 1 ? true : false}
                              onClick={(e) => {
                                if (data?.ORDER_ID) {
                                  setUnsubscribeDialog((prevState) => ({
                                    ...prevState,
                                    isOpen: true,
                                    dialogTitle: 'Deactive',
                                    dialogMsg: `Are you sure want to de-active the order ${data?.ORDER_ID} ?`,
                                    dialogBtnName: 'Deactive',
                                    showDialogBtn:
                                      new Date(data?.END_DATE).getTime() / 1000 >
                                      Math.floor(Date.now() / 1000)
                                        ? true
                                        : false,
                                    dialogComponent: '',
                                    dialogSubmitMethod: () => {
                                      handleDeactivateOrder(data);
                                    },
                                    dialogClearMethod: clearDialogState
                                  }));
                                }
                              }}
                            >
                              <Tooltip title="Deactivate Order">
                                <CancelRounded />
                              </Tooltip>
                            </IconButton>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={(e) => handleOrderDetails(e, data.ORDER_ID)}
                        >
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            <Typography color="textPrimary" variant="button">
                              {data.ID}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>{data.ORDER_ID}</StyledTableCell>
                        <StyledTableCell>{data.IDENTITY}</StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {data.ORDER_DATE
                              ? moment(data.ORDER_DATE).format('MMM Do YY')
                              : ''}
                          </p>
                          <p>
                            {data.ORDER_DATE
                              ? moment(data.ORDER_DATE).format('h:mm:ss A')
                              : ''}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {data.START_DATE
                              ? moment(data.START_DATE).format('MMM Do YY')
                              : ''}
                          </p>
                          <p>
                            {data.START_DATE
                              ? moment(data.START_DATE).format('h:mm:ss A')
                              : ''}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {data.END_DATE
                              ? moment(data.END_DATE).format('MMM Do YY')
                              : ''}
                          </p>
                          <p>
                            {data.END_DATE
                              ? moment(data.END_DATE).format('h:mm:ss A')
                              : ''}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>{data.PAYMENT_ID}</StyledTableCell>
                        <StyledTableCell>{data.PAYMENT_NAME}</StyledTableCell>
                        <StyledTableCell>{data.PLAN_ID}</StyledTableCell>
                        <StyledTableCell>{data.PLAN_NAME}</StyledTableCell>
                        <StyledTableCell>
                          <StyledChip
                            label={statusCheck(data.ORDER_STATUS, data.END_DATE).text}
                            color={statusCheck(data.ORDER_STATUS, data.END_DATE).color}
                            size="small"
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {data.PAYMENT_SOURCE === 'sist' && data.AUTO_RENEWAL === 1 ? (
                            <Chip label="Yes" size="small" color={'success'} />
                          ) : (
                            <Chip label="No" size="small" color={'default'} />
                          )}
                        </StyledTableCell> */}
                        <StyledTableCell>{data.PLAN_AMOUNT}</StyledTableCell>
                        <StyledTableCell>{data.TXN_AMOUNT}</StyledTableCell>
                        <StyledTableCell>{data.EVENT_TYPE}</StyledTableCell>
                        <StyledTableCell>
                          {data?.COUPON_CODE ? data?.COUPON_CODE : 'N/A'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data?.PRODUCT_NAME ? data?.PRODUCT_NAME : 'N/A'}
                        </StyledTableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Pagination
            count={pagination?.total_pages ? pagination?.total_pages : 0}
            onChange={handleChange}
            page={pagination?.current_page ? pagination?.current_page : 0}
            color="primary"
            className={classes.pagination}
            showFirstButton
            showLastButton
            sx={{
              display: 'flex',
              m: 2,
              justifyContent: 'right'
            }}
          />
        </Card>
      )}
      {unsubscribeDialog?.isOpen && (
        <DialogBox
          isOpen={unsubscribeDialog?.isOpen}
          dialogTitle={unsubscribeDialog?.dialogTitle}
          dialogMsg={unsubscribeDialog?.dialogMsg}
          dialogBtnName={unsubscribeDialog?.dialogBtnName}
          dialogSubmitMethod={unsubscribeDialog?.dialogSubmitMethod}
          dialogClearMethod={unsubscribeDialog?.dialogClearMethod}
          showDialogBtn={unsubscribeDialog?.showDialogBtn}
          dialogComponent={unsubscribeDialog?.dialogComponent}
        />
      )}
    </div>
  );
};

OrderList.propTypes = {
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  userDetails: PropTypes.object,
  pageNumber: PropTypes.func,
  dataLimit: PropTypes.func,
  pagination: PropTypes.object
};

export default OrderList;
