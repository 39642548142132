/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  FormLabel,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import { fetchPaymentMasterApi } from 'src/services/payment/paymentMaster';
import {
  addNewPaymentMapData,
  updatePaymentMapApi,
  fetchSinglePaymentMapData
} from 'src/services/payment/paymentMapping';

const validationSchema = yup.object({
  PAYMENT_ID: yup.number().required('Payment Id is required'),
  PAYMENT_NAME: yup.string().required('Payment Name is required'),
  PAYMENT_TYPE: yup.string().required('Payment Type is required'),
  PAYMENT_LOGO: yup.string().required('Payment Logo is required'),
  BANK_CODE: yup.string().required('Bank Code is required'),
  BANK_DISPLAY_NAME: yup.string().required('Bank Dispaly Name is required'),
  UPI_TYPE: yup.string().required('UPI Type is required'),
  DISPLAY_ORDER: yup.number().required('Display Order is required')
  // STATUS: yup.boolean().required('Status is required'),
});

const PaymentMappingForm = () => {
  const navigate = useNavigate();
  const { payMapId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [payment, setPayment] = useState([]);
  const [editPayMapDetails, setEditPayMapDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const formik = useFormik({
    initialValues: {
      PAYMENT_ID: editPayMapDetails['PAYMENT_ID'] ? editPayMapDetails['PAYMENT_ID'] : '',
      PAYMENT_NAME: editPayMapDetails['PAYMENT_NAME']?.toString()
        ? editPayMapDetails['PAYMENT_NAME']?.toString()
        : '',
      PAYMENT_TYPE: editPayMapDetails['PAYMENT_TYPE']?.toString()
        ? editPayMapDetails['PAYMENT_TYPE']?.toString()
        : '',
      PAYMENT_LOGO: editPayMapDetails['PAYMENT_LOGO']?.toString()
        ? editPayMapDetails['PAYMENT_LOGO']?.toString()
        : '',
      BANK_CODE: editPayMapDetails['BANK_CODE']?.toString()
        ? editPayMapDetails['BANK_CODE']?.toString()
        : '',
      BANK_DISPLAY_NAME: editPayMapDetails['BANK_DISPLAY_NAME']?.toString()
        ? editPayMapDetails['BANK_DISPLAY_NAME']?.toString()
        : '',
      UPI_TYPE: editPayMapDetails['UPI_TYPE']?.toString()
        ? editPayMapDetails['UPI_TYPE']?.toString()
        : '',
      DISPLAY_ORDER: editPayMapDetails['DISPLAY_ORDER']?.toString()
        ? editPayMapDetails['DISPLAY_ORDER']?.toString()
        : '',
      STATUS: editPayMapDetails['STATUS']?.toString()
        ? editPayMapDetails['STATUS']?.toString()
        : '0'
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        PAYMENT_MAPPING_MAST_ID: payMapId ? payMapId : '',
        PAYMENT_ID: parseInt(value['PAYMENT_ID']),
        DISPLAY_ORDER: parseInt(value['DISPLAY_ORDER']),
        STATUS: parseInt(value['STATUS'])
        // LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };
      if (payMapId) {
        updatePaymentMapApi({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addNewPaymentMapData({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }
    }
  });

  useEffect(() => {
    if (payMapId) {
      fetchSinglePaymentMapData(payMapId)
        .then((res) => {
          setEditPayMapDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/payment/mapping');
        });
    }
    //payment
    fetchPaymentMasterApi(1, 10, true)
      .then((res) => {
        setPayment(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {payMapId ? 'Edit' : 'Add New'} Payment Mapping Master
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
            >
              <InputLabel id="demo-simple-select-required-label">
                Select Payment
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                //   value={age}
                label="Select Payment *"
                name="PAYMENT_ID"
                value={formik.values.PAYMENT_ID}
                onChange={formik.handleChange}
              >
                {payment?.map((el, i) => (
                  <MenuItem value={el?.PAYMENT_ID}>{el?.PAYMENT_NAME}</MenuItem>
                ))}
                {!payment && <MenuItem value={''}>--No Payments--</MenuItem>}
              </Select>
              <FormHelperText>
                {formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_NAME"
              value={formik.values.PAYMENT_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_NAME && Boolean(formik.errors.PAYMENT_NAME)}
              helperText={formik.touched.PAYMENT_NAME && formik.errors.PAYMENT_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_TYPE"
              value={formik.values.PAYMENT_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_TYPE && Boolean(formik.errors.PAYMENT_TYPE)}
              helperText={formik.touched.PAYMENT_TYPE && formik.errors.PAYMENT_TYPE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Payment Logo"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PAYMENT_LOGO"
              value={formik.values.PAYMENT_LOGO}
              onChange={formik.handleChange}
              error={formik.touched.PAYMENT_LOGO && Boolean(formik.errors.PAYMENT_LOGO)}
              helperText={formik.touched.PAYMENT_LOGO && formik.errors.PAYMENT_LOGO}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Bank Code"
              style={{ width: '100%', marginBottom: '10px' }}
              name="BANK_CODE"
              value={formik.values.BANK_CODE}
              onChange={formik.handleChange}
              error={formik.touched.BANK_CODE && Boolean(formik.errors.BANK_CODE)}
              helperText={formik.touched.BANK_CODE && formik.errors.BANK_CODE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Bank Display Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="BANK_DISPLAY_NAME"
              value={formik.values.BANK_DISPLAY_NAME}
              onChange={formik.handleChange}
              error={
                formik.touched.BANK_DISPLAY_NAME &&
                Boolean(formik.errors.BANK_DISPLAY_NAME)
              }
              helperText={
                formik.touched.BANK_DISPLAY_NAME && formik.errors.BANK_DISPLAY_NAME
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="UPI Type"
              style={{ width: '100%', marginBottom: '10px' }}
              name="UPI_TYPE"
              value={formik.values.UPI_TYPE}
              onChange={formik.handleChange}
              error={formik.touched.UPI_TYPE && Boolean(formik.errors.UPI_TYPE)}
              helperText={formik.touched.UPI_TYPE && formik.errors.UPI_TYPE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Display Order"
              style={{ width: '100%', marginBottom: '10px' }}
              name="DISPLAY_ORDER"
              type="number"
              value={formik.values.DISPLAY_ORDER}
              onChange={formik.handleChange}
              error={formik.touched.DISPLAY_ORDER && Boolean(formik.errors.DISPLAY_ORDER)}
              helperText={formik.touched.DISPLAY_ORDER && formik.errors.DISPLAY_ORDER}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              component="fieldset"
              error={formik.touched.STATUS && Boolean(formik.errors.STATUS)}
            >
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                name="STATUS"
                value={formik.values.STATUS}
                onChange={formik.handleChange}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value="1" control={<Radio />} label="Active" />
                  <FormControlLabel value="0" control={<Radio />} label="De-Active" />
                </div>
              </RadioGroup>
              <FormHelperText>
                {formik.touched.STATUS && formik.errors.STATUS}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/payment/mapping');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default PaymentMappingForm;
