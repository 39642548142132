import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Settings as SettingsIcon
  // Lock as LockIcon,
  // ShoppingBag as ShoppingBagIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon
} from 'react-feather';

export const routes = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    user_type: ['A', 'S', 'M']
  },
  // {
  //   href: '/app/customers',
  //   icon: UsersIcon,
  //   title: 'Customers'
  // },
  {
    href: '#',
    icon: SettingsIcon,
    title: 'Plan Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Plan Details',
        href: '/plan/details'
      },
      {
        id: 2,
        title: 'Plan Master',
        href: '/plan/master'
      },
      {
        id: 3,
        title: 'Plan Sequence',
        href: '/plan/sequence'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Payment Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Payment Mapping',
        href: '/payment/mapping'
      },
      {
        id: 2,
        title: 'Payment Master',
        href: '/payment/master'
      },
      {
        id: 3,
        title: 'Payment Sequence',
        href: '/payment/Sequence'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'User Admin',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Register',
        href: '/admin_user/register'
      },
      // {
      //   id: 2,
      //   title: 'Manage Rights',
      //   href: '/admin_user/manage_rights'
      // },
      {
        id: 3,
        title: 'User Listing',
        href: '/admin_user/user_listing'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Coupon Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Get Coupon List',
        href: '#'
      },
      {
        id: 2,
        title: 'Add Coupon',
        href: '/coupon_management/add_coupon'
      },
      {
        id: 3,
        title: 'Get Offer List',
        href: '#'
      },
      {
        id: 4,
        title: 'Add Offer',
        href: '#'
      },
      {
        id: 5,
        title: 'Add Campaign (QA)',
        href: '/coupon_management/add_campaign'
      },
      {
        id: 6,
        title: 'Get Campaign List (QA)',
        href: '#'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Order & Subscription Management',
    user_type: ['A', 'S'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Orders',
        href: '/order/orders'
      },
      {
        id: 2,
        title: 'Assign Subscription',
        href: '/order/assign-subscription'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Reports',
    user_type: ['A'],
    nestedRoutes: [
      // {
      //   id: 1,
      //   title: 'Reports',
      //   href: '/report/reports'
      // },
      // {
      //   id: 1,
      //   title: 'Vendor Reports',
      //   href: '/report/vendor'
      // },
      {
        id: 2,
        title: 'Order Reports',
        href: '/report/order'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Reports Fot MIS',
    user_type: ['A', 'M'],
    nestedRoutes: [
      {
        id: 2,
        title: 'Order MIS Reports',
        href: '/report/ordermis'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Product Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Product',
        href: '/product/products'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Platform Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Platform Master',
        href: '/platform/platforms'
      }
    ]
  },
  {
    href: '#',
    icon: AlertCircleIcon,
    title: 'Banner Management',
    user_type: ['A'],
    nestedRoutes: [
      {
        id: 1,
        title: 'Add Banner',
        href: '/banner'
      }
    ]
  }
];

export default { routes };
