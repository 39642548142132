/* eslint-disable object-curly-newline */
import { styled, makeStyles } from '@material-ui/styles';
import { tableCellClasses, TableCell, Chip, chipClasses } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0.5rem',
    textAlign: 'center',
    letterSpacing: '0.7px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '0.5rem',
    textAlign: 'center',
    letterSpacing: '0.7px'
  }
}));

export const StyledChip = styled(Chip)(() => ({
  [`&.${chipClasses.root}`]: {
    fontSize: 14,
    padding: '0.5rem',
    display: 'flex',
    textAlign: 'center'
  }
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2)
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    '& .MuiTablePagination-select': {
      color: 'black'
    }
  }
}));
