/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Typography,
  FormHelperText
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  addPlanDetailApi,
  getPlanDetailsByIdApi,
  updatePlanDetailApi
} from 'src/services/plans/planDetails';
import { getPlanMasterApi } from 'src/services/plans/planMaster';
import { fetchPlatformsApi } from 'src/services/platform/platform';
import { fetchProductMasterData } from 'src/services/product/product';
import { fetchPaymentMasterApi } from 'src/services/payment/paymentMaster';
import CustomNotifier from 'src/customComponents/Notifier';

const validationSchema = yup.object({
  PLAN_ID: yup.number().required('Plan id is required'),
  PRODUCT_ID: yup.number().required('Product id is required'),
  PLATFORM_ID: yup.number().required('Platform id is required'),
  PAYMENT_ID: yup.number().required('payment id is required'),
  // PLAN_CAPTION: yup.string().required('Plan Caption is required'),
  PLAN_COUNTRY: yup.string().required('plan country is required'),
  PLAN_CURRENCY: yup.string().required('plan currency is required'),
  PLAN_CURRENCY_SYMBOL: yup.string().required('plan currency symbol is required'),
  PLAN_PRICE: yup.number().required('plan price is required'),
  ORIGINAL_PRICE: yup.number().required('original price is required'),
  RENEWAL_PRICE: yup.number().required('renewal price is required'),
  PLAN_TYPE: yup.string().required('plan type is required'),
  // IS_AUTO_RENEWAL: yup.number().required('is auto renewal, is required'),
  PLAN_DURATION: yup.number().required('plan duration is required'),
  PLAN_DOWNLOADS: yup.number().required('plan downloads is required')
  // PLAN_DETAILS: yup.string().required('plan details is required'),
  // STATUS: yup.number().required('status is required'),
  // STORE_PAYMENT_ID: yup.string().required('store payment id, is required'),
  // MULTI_PRICE: yup.string().required('multi price, is required'),
  // COINS: yup.number().required('coins is required'),
  // OFFER_TEXT: yup.string().required('offer text is required'),
  // OFFER_TEXT_DETAILS: yup.string().required('offer text details is required'),
  // IS_FEATURED: yup.number().required('is featured, is required'),
  // IS_BANNER_PRICE: yup.number().required('is banner price, is required'),
  // CONTENT_EVENT_MAPPING: yup.string().required('content event mapping, is required'),
  // PLAN_IDENTIFIER: yup.string().required('plan identifier, is required'),
  // ABOUT_PLAN: yup.string().required('about plan, is required'),
  // DISPLAY: yup.string().required('display, is required'),
  // FREE_TRIAL_DAYS: yup.number().required('free trial days, is required'),
  // PLAN_TITLE: yup.string().required('plan title, is required'),
  // PLAN_BENEFITS: yup.string().required('plan benefits, is required')
  // SHOW_ADS: yup.number().required('show ads, is required')
});

const PlanDetailsForm = () => {
  //param
  const { planDetailId } = useParams();
  const [editPlanDetails, setEditPlanDetails] = useState({});
  const [plan, setPlan] = useState([]);
  const [product, setProduct] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [payment, setPayment] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });

  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const formik = useFormik({
    initialValues: {
      PLAN_ID: editPlanDetails['PLAN_ID'] ? editPlanDetails['PLAN_ID'] : '',
      PRODUCT_ID: editPlanDetails['PRODUCT_ID'] ? editPlanDetails['PLAN_ID'] : '',
      PLATFORM_ID: editPlanDetails['PLATFORM_ID'] ? editPlanDetails['PLATFORM_ID'] : '',
      PAYMENT_ID: editPlanDetails['PAYMENT_ID'] ? editPlanDetails['PAYMENT_ID'] : '',
      PLAN_CAPTION: editPlanDetails['PLAN_CAPTION']
        ? editPlanDetails['PLAN_CAPTION']
        : '',
      PLAN_COUNTRY: editPlanDetails['PLAN_COUNTRY']
        ? editPlanDetails['PLAN_COUNTRY']
        : '',
      PLAN_CURRENCY: editPlanDetails['PLAN_CURRENCY']
        ? editPlanDetails['PLAN_CURRENCY']
        : '',
      PLAN_CURRENCY_SYMBOL: editPlanDetails['PLAN_CURRENCY_SYMBOL']
        ? editPlanDetails['PLAN_CURRENCY_SYMBOL']
        : '',
      PLAN_PRICE: editPlanDetails['PLAN_PRICE'] ? editPlanDetails['PLAN_PRICE'] : '',
      ORIGINAL_PRICE: editPlanDetails['ORIGINAL_PRICE']
        ? editPlanDetails['ORIGINAL_PRICE']
        : '',
      RENEWAL_PRICE: editPlanDetails['RENEWAL_PRICE']
        ? editPlanDetails['RENEWAL_PRICE']
        : '',
      PLAN_TYPE: editPlanDetails['PLAN_TYPE'] ? editPlanDetails['PLAN_TYPE'] : '',
      IS_AUTO_RENEWAL: editPlanDetails['IS_AUTO_RENEWAL']?.toString()
        ? editPlanDetails['IS_AUTO_RENEWAL']?.toString()
        : '0',
      PLAN_DURATION: editPlanDetails['PLAN_DURATION']
        ? editPlanDetails['PLAN_DURATION']
        : '',
      PLAN_DOWNLOADS: editPlanDetails['PLAN_DOWNLOADS']?.toString()
        ? editPlanDetails['PLAN_DOWNLOADS']?.toString()
        : '',
      PLAN_DETAILS: editPlanDetails['PLAN_DETAILS']
        ? editPlanDetails['PLAN_DETAILS']
        : '',
      STATUS: editPlanDetails['STATUS']?.toString()
        ? editPlanDetails['STATUS']?.toString()
        : '0',
      STORE_PAYMENT_ID: editPlanDetails['STORE_PAYMENT_ID']
        ? editPlanDetails['STORE_PAYMENT_ID']
        : '',
      MULTI_PRICE: editPlanDetails['MULTI_PRICE'] ? editPlanDetails['MULTI_PRICE'] : '0',
      COINS: editPlanDetails['COINS'] ? editPlanDetails['COINS'] : '',
      OFFER_TEXT: editPlanDetails['OFFER_TEXT']?.toString()
        ? editPlanDetails['OFFER_TEXT']?.toString()
        : '',
      OFFER_TEXT_DETAILS: editPlanDetails['OFFER_TEXT_DETAILS']?.toString()
        ? editPlanDetails['OFFER_TEXT_DETAILS']?.toString()
        : '',
      IS_FEATURED: editPlanDetails['IS_FEATURED'] ? editPlanDetails['IS_FEATURED'] : '0',
      IS_BANNER_PRICE: editPlanDetails['IS_BANNER_PRICE']
        ? editPlanDetails['IS_BANNER_PRICE']
        : '0',
      CONTENT_EVENT_MAPPING: editPlanDetails['CONTENT_EVENT_MAPPING']?.toString()
        ? editPlanDetails['CONTENT_EVENT_MAPPING']?.toString()
        : '',
      PLAN_IDENTIFIER: editPlanDetails['PLAN_IDENTIFIER']?.toString()
        ? editPlanDetails['PLAN_IDENTIFIER']?.toString()
        : '',
      ABOUT_PLAN: editPlanDetails['ABOUT_PLAN']?.toString()
        ? editPlanDetails['ABOUT_PLAN']?.toString()
        : '',
      DISPLAY: editPlanDetails['DISPLAY'] ? editPlanDetails['DISPLAY'] : '',
      FREE_TRIAL_DAYS: editPlanDetails['FREE_TRIAL_DAYS']?.toString()
        ? editPlanDetails['FREE_TRIAL_DAYS']?.toString()
        : '',
      PLAN_TITLE: editPlanDetails['PLAN_TITLE'] ? editPlanDetails['PLAN_TITLE'] : '',
      PLAN_BENEFITS: editPlanDetails['PLAN_BENEFITS']
        ? editPlanDetails['PLAN_BENEFITS']
        : '',
      SHOW_ADS: editPlanDetails['SHOW_ADS'] ? editPlanDetails['SHOW_ADS'] : '0'
      // ADDED_BY: editPlanDetails['ADDED_BY'] ? editPlanDetails['ADDED_BY'] : 1
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let addedBy = 1;
      let addedOn = Math.floor(Date.now() / 1000);
      let lastMod = Math.floor(Date.now() / 1000);
      let resValues = {
        ...values,
        PLAN_DETAILS_ID: planDetailId ? planDetailId : '',
        PLAN_ID: parseInt(values['PLAN_ID']),
        PRODUCT_ID: parseInt(values['PRODUCT_ID']),
        PLATFORM_ID: parseInt(values['PLATFORM_ID']),
        PAYMENT_ID: parseInt(values['PAYMENT_ID']),
        PLAN_PRICE: parseFloat(values['PLAN_PRICE']),
        ORIGINAL_PRICE: parseFloat(values['ORIGINAL_PRICE']),
        RENEWAL_PRICE: parseFloat(values['RENEWAL_PRICE']),
        IS_AUTO_RENEWAL: parseInt(values['IS_AUTO_RENEWAL']),
        PLAN_DURATION: parseInt(values['PLAN_DURATION']),
        PLAN_DOWNLOADS: parseInt(values['PLAN_DOWNLOADS']),
        STATUS: parseInt(values['STATUS']),
        MULTI_PRICE: parseInt(values['MULTI_PRICE']),
        IS_FEATURED: parseInt(values['IS_FEATURED']),
        IS_BANNER_PRICE: parseInt(values['IS_BANNER_PRICE']),
        FREE_TRIAL_DAYS: parseInt(values['FREE_TRIAL_DAYS']),
        SHOW_ADS: parseInt(values['SHOW_ADS']),
        ADDED_BY: addedBy
      };

      console.log(values);
      console.log('resValues', resValues);
      if (planDetailId) {
        updatePlanDetailApi({ ...resValues })
          .then((res) => {
            // console.log('res::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            console.log('catch res::', res);
            setSnackBarOpen(true, error);
          });
      } else {
        addPlanDetailApi({ ...resValues })
          .then((res) => {
            // console.log('res::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            console.log('catch res::', error);
            setSnackBarOpen(true, error);
          });
      }
    }
  });

  useEffect(() => {
    //get plandetails by id
    getPlanDetailsByIdApi(planDetailId)
      .then((res) => {
        // console.log('RESS', res, res?.tableData[0]);
        setEditPlanDetails(res?.tableData[0]);
      })
      .catch((err) => {
        setSnackBarOpen(true, error);
      });

    //plan
    getPlanMasterApi(1, 10, true)
      .then((res) => {
        setPlan(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //platform
    fetchPlatformsApi(1, 10, true)
      .then((res) => {
        setPlatform(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //product
    fetchProductMasterData(1, 10, true)
      .then((res) => {
        setProduct(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });

    //payment
    fetchPaymentMasterApi(1, 10, true)
      .then((res) => {
        setPayment(res?.tableData);
      })
      .catch((error) => {
        setSnackBarOpen(true, error);
      });
  }, []);

  return (
    <Container style={{ marginTop: '1rem' }}>
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Box margin={2}>
        <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
          {planDetailId ? 'Edit' : 'Add New'} Plan Detail
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={formik.touched.PLAN_ID && formik.errors.PLAN_ID}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Select Plan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  //   value={age}
                  label="Select Plan *"
                  name="PLAN_ID"
                  value={formik.values.PLAN_ID}
                  onChange={formik.handleChange}
                >
                  {plan?.map((el, i) => (
                    <MenuItem value={el?.PLAN_ID}>{el?.PLAN_NAME}</MenuItem>
                  ))}
                  {!plan && <MenuItem value={''}>--No Plans--</MenuItem>}
                </Select>
                <FormHelperText>
                  {formik.touched.PLAN_ID && formik.errors.PLAN_ID}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={formik.touched.PRODUCT_ID && formik.errors.PRODUCT_ID}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Select Product
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select"
                  //   value={age}
                  label="Select Product"
                  name="PRODUCT_ID"
                  value={formik.values.PRODUCT_ID}
                  onChange={formik.handleChange}
                >
                  {product?.map((el, i) => (
                    <MenuItem value={el?.PRODUCT_ID}>{el?.PRODUCT_NAME}</MenuItem>
                  ))}
                  {!product && <MenuItem value={''}>--No Products--</MenuItem>}
                </Select>
                <FormHelperText>
                  {formik.touched.PRODUCT_ID && formik.errors.PRODUCT_ID}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Select Platform
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select"
                  //   value={age}
                  label="Select Platform"
                  name="PLATFORM_ID"
                  value={formik.values.PLATFORM_ID}
                  onChange={formik.handleChange}
                >
                  {platform?.map((el, i) => (
                    <MenuItem value={el?.PLATFORM_ID}>{el?.PLATFORM_NAME}</MenuItem>
                  ))}
                  {!platform && <MenuItem value={''}>--No Platforms--</MenuItem>}
                </Select>
                <FormHelperText>
                  {formik.touched.PLATFORM_ID && formik.errors.PLATFORM_ID}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Select Payment
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select"
                  //   value={age}
                  label="Select Payment"
                  name="PAYMENT_ID"
                  value={formik.values.PAYMENT_ID}
                  onChange={formik.handleChange}
                >
                  {payment?.map((el, i) => (
                    <MenuItem value={el?.PAYMENT_ID}>{el?.PAYMENT_NAME}</MenuItem>
                  ))}
                  {!payment && <MenuItem value={''}>--No Payments--</MenuItem>}
                </Select>
                <FormHelperText>
                  {formik.touched.PAYMENT_ID && formik.errors.PAYMENT_ID}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Caption"
                  name="PLAN_CAPTION"
                  value={formik.values.PLAN_CAPTION}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_CAPTION && Boolean(formik.errors.PLAN_CAPTION)
                  }
                  helperText={formik.touched.PLAN_CAPTION && formik.errors.PLAN_CAPTION}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Country"
                  name="PLAN_COUNTRY"
                  // defaultValue="Plan Country"
                  value={formik.values.PLAN_COUNTRY}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_COUNTRY && Boolean(formik.errors.PLAN_COUNTRY)
                  }
                  helperText={formik.touched.PLAN_COUNTRY && formik.errors.PLAN_COUNTRY}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Currency"
                  name="PLAN_CURRENCY"
                  value={formik.values.PLAN_CURRENCY}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_CURRENCY && Boolean(formik.errors.PLAN_CURRENCY)
                  }
                  helperText={formik.touched.PLAN_CURRENCY && formik.errors.PLAN_CURRENCY}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Currency Symbol"
                  name="PLAN_CURRENCY_SYMBOL"
                  value={formik.values.PLAN_CURRENCY_SYMBOL}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_CURRENCY_SYMBOL &&
                    Boolean(formik.errors.PLAN_CURRENCY_SYMBOL)
                  }
                  helperText={
                    formik.touched.PLAN_CURRENCY_SYMBOL &&
                    formik.errors.PLAN_CURRENCY_SYMBOL
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Price"
                  name="PLAN_PRICE"
                  value={formik.values.PLAN_PRICE}
                  onChange={formik.handleChange}
                  error={formik.touched.PLAN_PRICE && Boolean(formik.errors.PLAN_PRICE)}
                  helperText={formik.touched.PLAN_PRICE && formik.errors.PLAN_PRICE}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Original Price"
                  name="ORIGINAL_PRICE"
                  value={formik.values.ORIGINAL_PRICE}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.ORIGINAL_PRICE && Boolean(formik.errors.ORIGINAL_PRICE)
                  }
                  helperText={
                    formik.touched.ORIGINAL_PRICE && formik.errors.ORIGINAL_PRICE
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Renewal Price"
                  name="RENEWAL_PRICE"
                  value={formik.values.RENEWAL_PRICE}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.RENEWAL_PRICE && Boolean(formik.errors.RENEWAL_PRICE)
                  }
                  helperText={formik.touched.RENEWAL_PRICE && formik.errors.RENEWAL_PRICE}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Type"
                  name="PLAN_TYPE"
                  value={formik.values.PLAN_TYPE}
                  onChange={formik.handleChange}
                  error={formik.touched.PLAN_TYPE && Boolean(formik.errors.PLAN_TYPE)}
                  helperText={formik.touched.PLAN_TYPE && formik.errors.PLAN_TYPE}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend"> Is Auto Renewal</FormLabel>
                <RadioGroup
                  row
                  aria-label="IS_AUTO_RENEWAL"
                  name="IS_AUTO_RENEWAL"
                  value={formik.values.IS_AUTO_RENEWAL}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Duration"
                  name="PLAN_DURATION"
                  value={formik.values.PLAN_DURATION}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_DURATION && Boolean(formik.errors.PLAN_DURATION)
                  }
                  helperText={formik.touched.PLAN_DURATION && formik.errors.PLAN_DURATION}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Downloads"
                  name="PLAN_DOWNLOADS"
                  value={formik.values.PLAN_DOWNLOADS}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_DOWNLOADS && Boolean(formik.errors.PLAN_DOWNLOADS)
                  }
                  helperText={
                    formik.touched.PLAN_DOWNLOADS && formik.errors.PLAN_DOWNLOADS
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Details"
                  name="PLAN_DETAILS"
                  value={formik.values.PLAN_DETAILS}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_DETAILS && Boolean(formik.errors.PLAN_DETAILS)
                  }
                  helperText={formik.touched.PLAN_DETAILS && formik.errors.PLAN_DETAILS}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="STATUS"
                  value={formik.values.STATUS}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Active" />
                  <FormControlLabel value={'0'} control={<Radio />} label="InActive" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Store Payment ID"
                  name="STORE_PAYMENT_ID"
                  value={formik.values.STORE_PAYMENT_ID}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend"> Is Multi Price</FormLabel>
                <RadioGroup
                  row
                  aria-label="MULTI_PRICE"
                  name="MULTI_PRICE"
                  value={formik.values.MULTI_PRICE}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Coins"
                  name="COINS"
                  type="number"
                  value={formik.values.COINS}
                  onChange={formik.handleChange}
                  error={formik.touched.COINS && Boolean(formik.errors.COINS)}
                  helperText={formik.touched.COINS && formik.errors.COINS}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Offer Text"
                  name="OFFER_TEXT"
                  value={formik.values.OFFER_TEXT}
                  onChange={formik.handleChange}
                  error={formik.touched.OFFER_TEXT && Boolean(formik.errors.OFFER_TEXT)}
                  helperText={formik.touched.OFFER_TEXT && formik.errors.OFFER_TEXT}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  rows={4}
                  multiline
                  label="Offer Text Details"
                  name="OFFER_TEXT_DETAILS"
                  value={formik.values.OFFER_TEXT_DETAILS}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.OFFER_TEXT_DETAILS &&
                    Boolean(formik.errors.OFFER_TEXT_DETAILS)
                  }
                  helperText={
                    formik.touched.OFFER_TEXT_DETAILS && formik.errors.OFFER_TEXT_DETAILS
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Is Featured</FormLabel>
                <RadioGroup
                  row
                  aria-label="featured"
                  name="IS_FEATURED"
                  value={formik.values.IS_FEATURED}
                  onChange={formik.handleChange}
                  error={formik.touched.IS_FEATURED && Boolean(formik.errors.IS_FEATURED)}
                  helperText={formik.touched.IS_FEATURED && formik.errors.IS_FEATURED}
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Is Banner Price</FormLabel>
                <RadioGroup
                  row
                  aria-label="isBannerPrice"
                  name="IS_BANNER_PRICE"
                  value={formik.values.IS_BANNER_PRICE}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.IS_BANNER_PRICE &&
                    Boolean(formik.errors.IS_BANNER_PRICE)
                  }
                  helperText={
                    formik.touched.IS_BANNER_PRICE && formik.errors.IS_BANNER_PRICE
                  }
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Content Event Mapping"
                  name="CONTENT_EVENT_MAPPING"
                  value={formik.values.CONTENT_EVENT_MAPPING}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.CONTENT_EVENT_MAPPING &&
                    Boolean(formik.errors.CONTENT_EVENT_MAPPING)
                  }
                  helperText={
                    formik.touched.CONTENT_EVENT_MAPPING &&
                    formik.errors.CONTENT_EVENT_MAPPING
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Identifier"
                  name="PLAN_IDENTIFIER"
                  value={formik.values.PLAN_IDENTIFIER}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_IDENTIFIER &&
                    Boolean(formik.errors.PLAN_IDENTIFIER)
                  }
                  helperText={
                    formik.touched.PLAN_IDENTIFIER && formik.errors.PLAN_IDENTIFIER
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="About Plan"
                  name="ABOUT_PLAN"
                  value={formik.values.ABOUT_PLAN}
                  onChange={formik.handleChange}
                  error={formik.touched.ABOUT_PLAN && Boolean(formik.errors.ABOUT_PLAN)}
                  helperText={formik.touched.ABOUT_PLAN && formik.errors.ABOUT_PLAN}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Display"
                  name="DISPLAY"
                  value={formik.values.DISPLAY}
                  onChange={formik.handleChange}
                  error={formik.touched.DISPLAY && Boolean(formik.errors.DISPLAY)}
                  helperText={formik.touched.DISPLAY && formik.errors.DISPLAY}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Free Trial Days"
                  name="FREE_TRIAL_DAYS"
                  type="number"
                  value={formik.values.FREE_TRIAL_DAYS}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.FREE_TRIAL_DAYS &&
                    Boolean(formik.errors.FREE_TRIAL_DAYS)
                  }
                  helperText={
                    formik.touched.FREE_TRIAL_DAYS && formik.errors.FREE_TRIAL_DAYS
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Title"
                  name="PLAN_TITLE"
                  value={formik.values.PLAN_TITLE}
                  onChange={formik.handleChange}
                  error={formik.touched.PLAN_TITLE && Boolean(formik.errors.PLAN_TITLE)}
                  helperText={formik.touched.PLAN_TITLE && formik.errors.PLAN_TITLE}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-required"
                  label="Plan Benefits"
                  name="PLAN_BENEFITS"
                  value={formik.values.PLAN_BENEFITS}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.PLAN_BENEFITS && Boolean(formik.errors.PLAN_BENEFITS)
                  }
                  helperText={formik.touched.PLAN_BENEFITS && formik.errors.PLAN_BENEFITS}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Show Ads</FormLabel>
                <RadioGroup
                  row
                  aria-label="showAds"
                  name="SHOW_ADS"
                  value={formik.values.SHOW_ADS}
                  onChange={formik.handleChange}
                  error={formik.touched.SHOW_ADS && Boolean(formik.errors.SHOW_ADS)}
                  helperText={formik.touched.SHOW_ADS && formik.errors.SHOW_ADS}
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button color="primary" variant="contained" fullWidth type="submit">
                {planDetailId ? 'Edit' : 'Add'}
              </Button>
            </Grid>
            {/* <Grid item xs={12} sm={6}></Grid> */}
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default PlanDetailsForm;
