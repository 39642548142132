/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Stack,
  IconButton,
  Avatar
} from '@material-ui/core';
import { TableLoading } from 'src/customComponents/loadingScreen';
import {
  StyledTableCell,
  useStyles,
  StyledChip
} from 'src/components/common/tableStyles';

import { statusCheck, statusEnableDisable } from 'src/utils/statusCheck';
import { Delete, Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import moment from 'moment';

const ProductMasterList = ({
  isLoading,
  pageNumber,
  dataLimit,
  tableData,
  pagination,
  ...rest
}) => {
  const handleChange = (event, value) => pageNumber(value);
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Action</StyledTableCell>
                  <StyledTableCell>Product Id</StyledTableCell>
                  <StyledTableCell>Product Code</StyledTableCell>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell>Product Description</StyledTableCell>
                  <StyledTableCell>Product Logo</StyledTableCell>
                  <StyledTableCell>Added By</StyledTableCell>
                  {/* <StyledTableCell>Last Modified</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoading rows={6} columns={10} />
                ) : (
                  tableData.map((product) => (
                    <TableRow hover key={product.PRODUCT_ID} style={{ padding: 0 }}>
                      <StyledTableCell>
                        <Stack direction="col" spacing={2}>
                          <IconButton
                            color="default"
                            aria-label="edit"
                            onClick={(e) => {
                              if (product?.PRODUCT_ID) {
                                navigate(`/product/edit/${product?.PRODUCT_ID}`);
                              }
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton color="error" aria-label="del" onClick={(e) => {}}>
                            <Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>{product.PRODUCT_ID}</StyledTableCell>
                      <StyledTableCell>{product.PRODUCT_CODE}</StyledTableCell>
                      <StyledTableCell>{product.PRODUCT_NAME}</StyledTableCell>
                      <StyledTableCell>{product.PRODUCT_DESC}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar src={product?.PRODUCT_LOGO} sx={{ mr: 2 }} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{product?.USER_ID}</StyledTableCell>
                      {/* <StyledTableCell>
                        {moment(plan?.LAST_MODIFIED * 1000).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </StyledTableCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          count={pagination?.total_pages ? pagination?.total_pages : 0}
          onChange={handleChange}
          page={pagination?.current_page ? pagination?.current_page : 0}
          color="primary"
          className={classes.pagination}
          showFirstButton
          showLastButton
          sx={{
            display: 'flex',
            m: 2,
            justifyContent: 'right'
          }}
        />
      </Card>
    </div>
  );
};

ProductMasterList.propTypes = {
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  pageNumber: PropTypes.func,
  dataLimit: PropTypes.func,
  pagination: PropTypes.object
};

export default ProductMasterList;
