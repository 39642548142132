import React from 'react';
import ProductMasterForm from 'src/components/product/ProductMasterForm';

const AddEditProductMaster = () => (
  <div>
    <ProductMasterForm />
  </div>
);

export default AddEditProductMaster;
