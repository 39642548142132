import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const fetchPaymentMapping = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/mapping?page=${page}&view=${limit}&getAll=${getAll}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('paymentMapping', response);
    const dataTable = response?.data?.payment_mapping
      ? response.data.payment_mapping
      : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('paymentMappingERROR : ', error);
    throw error.message;
  }
};

export const fetchSearchData = async (page, limit, input) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/mapping/search?page=${page}&view=${limit}&input=${input}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('paymentMapping', response);
    const dataTable = response?.data?.payment_mapping
      ? response.data.payment_mapping
      : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('paymentPlatformERROR : ', error);
    throw error.message;
  }
};

export const fetchSinglePaymentMapData = async (payMapId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/mapping/${payMapId}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('fetchSinglePaymentMapping', response);
    return response;
  } catch (error) {
    logger('fetchSinglePaymentMapping ERROR : ', error);
    throw error.message;
  }
};

export const addNewPaymentMapData = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/mapping`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('add payment mapping', response);
    return response;
  } catch (error) {
    logger('add payment mapping Error : ', error);
    throw error.message;
  }
};

export const updatePaymentMapApi = async ({
  PAYMENT_MAPPING_MAST_ID,
  PAYMENT_ID,
  PAYMENT_NAME,
  PAYMENT_TYPE,
  PAYMENT_LOGO,
  BANK_CODE,
  BANK_DISPLAY_NAME,
  UPI_TYPE,
  DISPLAY_ORDER,
  STATUS,
  ADDED_BY
}) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/payment/mapping/update`,
      {
        PAYMENT_MAPPING_MAST_ID,
        PAYMENT_ID,
        PAYMENT_NAME,
        PAYMENT_TYPE,
        PAYMENT_LOGO,
        BANK_CODE,
        BANK_DISPLAY_NAME,
        UPI_TYPE,
        DISPLAY_ORDER,
        STATUS,
        ADDED_BY
      }
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const removePaymentMapByIdApi = async (id) => {
  try {
    const getData = await axios.delete(
      `${process.env.REACT_APP_API_URL}/payment/mapping/${id}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};

export const customData = '';
