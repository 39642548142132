import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const fetchPaymentMasterApi = async (page, limit, getAll = false) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/master?page=${page}&view=${limit}&getAll=${getAll}`
    );
    const response = getData?.data;
    if (response.statusCode !== 200) throw response;
    // logger('paymentMaster', response);
    const dataTable = response?.data?.payment_master ? response.data.payment_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('paymentMasterERROR : ', error);
    throw error.message;
  }
};

export const fetchSearchData = async (page, limit, input) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/master/search?page=${page}&view=${limit}&input=${input}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('paymentMaster', response);
    const dataTable = response?.data?.payment_master ? response.data.payment_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('PaymentMasterSearch Error : ', error);
    throw error.message;
  }
};

export const fetchSinglePaymentMasterData = async (paymentId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/master/${paymentId}`
    );
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('fetchSinglePaymentMaster', response);
    return response;
  } catch (error) {
    logger('fetchSinglePaymentMaster ERROR : ', error);
    throw error.message;
  }
};

export const addNewPaymentMasterData = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/master`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('add payment master', response);
    return response;
  } catch (error) {
    logger('add payment master Error : ', error);
    throw error.message;
  }
};

export const updatePaymentMasterApi = async ({
  PAYMENT_MAST_ID,
  PLAN_NAME,
  PLAN_TYPE,
  PAYMENT_DESC,
  PAYMENT_LOGO,
  IS_RENEWAL_AVAILABLE,
  STATUS,
  ADDED_BY
}) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/payment/master/update`,
      {
        PAYMENT_MAST_ID,
        PLAN_NAME,
        PLAN_TYPE,
        PAYMENT_DESC,
        PAYMENT_LOGO,
        IS_RENEWAL_AVAILABLE,
        STATUS,
        ADDED_BY
      }
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const removePaymentMasterByIdApi = async (id) => {
  try {
    const getData = await axios.delete(
      `${process.env.REACT_APP_API_URL}/payment/master/${id}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_master ? response.data.plan_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planMasterERROR : ', error);
    throw error.message;
  }
};

export const customData = '';
