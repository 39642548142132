/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  addPlatformApi,
  fetchSinglePlatformApi,
  updatePlatformApi
} from 'src/services/platform/platform';
import CustomNotifier from 'src/customComponents/Notifier';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  PLATFORM_CODE: yup.string().required('Platform Code is required'),
  PLATFORM_NAME: yup.string().required('Platform name is required')
});

const PlatformMasterForm = () => {
  const { platformId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [editPlatformDetails, setEditPlatformDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      PLATFORM_CODE: editPlatformDetails['PLATFORM_CODE']
        ? editPlatformDetails['PLATFORM_CODE']
        : '',
      PLATFORM_NAME: editPlatformDetails['PLATFORM_NAME']
        ? editPlatformDetails['PLATFORM_NAME']
        : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        PLATFORM_ID: platformId ? platformId : ''
      };
      if (platformId) {
        updatePlatformApi({ ...obj, USER_ID: addedBy })
          .then((res) => {
            // console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addPlatformApi({ ...obj, USER_ID: addedBy })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }

      console.log(values);
    }
  });

  useEffect(() => {
    //edit api
    if (platformId) {
      fetchSinglePlatformApi(platformId)
        .then((res) => {
          setEditPlatformDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/platform/platforms');
        });
    }
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {platformId ? 'Edit' : 'Add New'} Platform
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Platform Code"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLATFORM_CODE"
              value={formik.values.PLATFORM_CODE}
              onChange={formik.handleChange}
              error={formik.touched.PLATFORM_CODE && Boolean(formik.errors.PLATFORM_CODE)}
              helperText={formik.touched.PLATFORM_CODE && formik.errors.PLATFORM_CODE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Platform Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PLATFORM_NAME"
              value={formik.values.PLATFORM_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PLATFORM_NAME && Boolean(formik.errors.PLATFORM_NAME)}
              helperText={formik.touched.PLATFORM_NAME && formik.errors.PLATFORM_NAME}
            />
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/platform/platforms');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default PlatformMasterForm;
