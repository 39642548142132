/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Stack
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomNotifier from 'src/customComponents/Notifier';
import {
  addProductApi,
  fetchSingleProductMasterData,
  updateProductApi
} from 'src/services/product/product';

// Validates plan schema and returns validation schema. This is used to validate plans before they are created
const validationSchema = yup.object({
  PRODUCT_CODE: yup.string().required('Product Code is required'),
  PRODUCT_NAME: yup.string().required('Product Name is required'),
  PRODUCT_DESC: yup.string().required('Product Description is required'),
  PRODUCT_LOGO: yup.string().required('Product Logo is required')
});

const ProductMasterForm = () => {
  const { prodId } = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [editProductDetails, setEditProductDetails] = useState({});
  const setSnackBarOpen = (val, msg) => {
    if (snackBar.open === true) setSnackBar({ open: false, message: msg });
    setSnackBar({ open: val, message: msg });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      PRODUCT_CODE: editProductDetails['PRODUCT_CODE']
        ? editProductDetails['PRODUCT_CODE']
        : '',
      PRODUCT_NAME: editProductDetails['PRODUCT_NAME']
        ? editProductDetails['PRODUCT_NAME']
        : '',
      PRODUCT_DESC: editProductDetails['PRODUCT_DESC']
        ? editProductDetails['PRODUCT_DESC']
        : '',
      PRODUCT_LOGO: editProductDetails['PRODUCT_LOGO']?.toString()
        ? editProductDetails['PRODUCT_LOGO']?.toString()
        : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      let addedON = Math.floor(Date.now() / 1000);
      let addedBy = 1;
      const obj = {
        ...value,
        PRODUCT_ID: prodId ? prodId : '',
        USER_ID: addedBy
        // LAST_MODIFIED: Math.floor(Date.now() / 1000)
      };
      if (prodId) {
        updateProductApi({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            // console.log('res master ::', res);
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      } else {
        addProductApi({ ...obj, ADDED_BY: addedBy })
          .then((res) => {
            setSnackBarOpen(true, res.message);
          })
          .catch((error) => {
            setSnackBarOpen(true, error);
          });
      }

      console.log(values);
    }
  });

  useEffect(() => {
    //edit api
    if (prodId) {
      fetchSingleProductMasterData(prodId)
        .then((res) => {
          setEditProductDetails(res?.data[0]);
        })
        .catch((err) => {
          setSnackBarOpen(true, err.message);
          navigate('/product/products');
        });
    }
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 2
      }}
    >
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Typography fontWeight="bold" fontSize={20} marginBottom={3}>
        {prodId ? 'Edit' : 'Add New'} Product
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Product Code"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_CODE"
              value={formik.values.PRODUCT_CODE}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_CODE && Boolean(formik.errors.PRODUCT_CODE)}
              helperText={formik.touched.PRODUCT_CODE && formik.errors.PRODUCT_CODE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-disabled"
              label="Product Name"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_NAME"
              value={formik.values.PRODUCT_NAME}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_NAME && Boolean(formik.errors.PRODUCT_NAME)}
              helperText={formik.touched.PRODUCT_NAME && formik.errors.PRODUCT_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Description"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_DESC"
              value={formik.values.PRODUCT_DESC}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_DESC && Boolean(formik.errors.PRODUCT_DESC)}
              helperText={formik.touched.PRODUCT_DESC && formik.errors.PRODUCT_DESC}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Logo"
              style={{ width: '100%', marginBottom: '10px' }}
              name="PRODUCT_LOGO"
              value={formik.values.PRODUCT_LOGO}
              onChange={formik.handleChange}
              error={formik.touched.PRODUCT_LOGO && Boolean(formik.errors.PRODUCT_LOGO)}
              helperText={formik.touched.PRODUCT_LOGO && formik.errors.PRODUCT_LOGO}
            />
          </Grid>
        </Grid>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/product/products');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default ProductMasterForm;
