import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import PlanDetailsForm from 'src/components/plans/details/PlanDetailsForm';
// import CustomNotifier from 'src/customComponents/Notifier';

const AddEditPlanDetails = () => (
  <>
    <Helmet>
      <title> Add | Plan | Details </title>
    </Helmet>

    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <PlanDetailsForm />
    </Box>
  </>
);

export default AddEditPlanDetails;
