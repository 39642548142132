import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const CustomButton = styled(Button)(() => ({
  color: grey[100],
  backgroundColor: grey[900],
  '&:hover': {
    backgroundColor: grey[700],
  },
}));

export default CustomButton;
