import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/core';

export default function CustomNotifier({ setSnackBarOpen, message }) {
  const [open, setOpen] = useState(false);
  // const delay = (ms) => (new Promise((res) => setTimeout(res, ms)));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (setSnackBarOpen === true) setOpen(true);
  }, [setSnackBarOpen, message]);

  // const action = (
  //   <>
  //     <Button
  //       anchororigin={{ vertical: 'top', horizontal: 'left' }}
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <CloseIcon fontSize="small" />
  //     </Button>
  //   </>
  // );

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        message={message}
        style={{
          marginTop: '-15px'
        }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
        {/* <SnackbarContent message={message} /> */}
      </Snackbar>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
    </div>
  );
}

CustomNotifier.propTypes = {
  setSnackBarOpen: PropTypes.bool,
  message: PropTypes.string.isRequired
};
