import PropTypes from 'prop-types';
import {
  CircularProgress,
  Skeleton,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';

export const TableLoading = ({ rows = 10, columns = 10 }) => {
  const skelRows = [];
  const skelColumns = [];
  for (let i = 0; i < rows; i++) {
    skelColumns.push(
      <td key={i}>
        <Skeleton key={i} width="70%" height="100%" animation="wave">
          <TableCell key={i}>
            <Typography color="textPrimary" variant="body1" />
          </TableCell>
        </Skeleton>
      </td>
    );
  }
  for (let i = 0; i < columns; i++) {
    skelRows.push(
      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {skelColumns}
      </TableRow>
    );
  }
  return skelRows;
};

TableLoading.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number
};

export const LoadingScreen = () => <CircularProgress size={100} />;
