import AssignSubscriptionForm from 'src/components/order/AssignSubscriptionForm';
import { Helmet } from 'react-helmet';

const OrderDetails = () => (
  <>
    <Helmet>
      <title> Subscription </title>
    </Helmet>
    <div>
      <AssignSubscriptionForm />
    </div>
  </>
);

export default OrderDetails;
