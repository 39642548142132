/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Chip,
  TableSortLabel,
  IconButton,
  Stack
} from '@material-ui/core';
import { Edit, Delete, DeleteForever } from '@material-ui/icons';
import { TableLoading } from 'src/customComponents/loadingScreen';
import { StyledChip, StyledTableCell, useStyles } from '../../common/tableStyles';
// import AlertDialog from 'src/customComponents/AlertDialog';

const PlanList = ({
  isLoading,
  pageNumber,
  dataLimit,
  tableData,
  pagination,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleChange = (event, value) => pageNumber(value);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1600 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Action</StyledTableCell>
                  <StyledTableCell>Plan Details Id</StyledTableCell>
                  <StyledTableCell>Plan Id</StyledTableCell>
                  <StyledTableCell>Product Id</StyledTableCell>
                  <StyledTableCell>Platfrom Id</StyledTableCell>
                  <StyledTableCell>Payment Id</StyledTableCell>
                  <StyledTableCell>Plan Caption</StyledTableCell>
                  <StyledTableCell>Plan Country</StyledTableCell>
                  <StyledTableCell>Plan Currency</StyledTableCell>
                  <StyledTableCell>Plan Currency Symbol</StyledTableCell>
                  <StyledTableCell>Plan Price</StyledTableCell>
                  <StyledTableCell>Original Price</StyledTableCell>
                  <StyledTableCell>Renewal Price</StyledTableCell>
                  <StyledTableCell>Plan Type</StyledTableCell>
                  <StyledTableCell>Is Auto Renewal</StyledTableCell>
                  <StyledTableCell>Plan Duration</StyledTableCell>
                  <StyledTableCell>Plan Downloads</StyledTableCell>
                  <StyledTableCell>Plan Details</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Store Payment Id</StyledTableCell>
                  <StyledTableCell>Multi Price</StyledTableCell>
                  <StyledTableCell>Coins</StyledTableCell>
                  <StyledTableCell>Offer Text</StyledTableCell>
                  <StyledTableCell>Offer Text Details</StyledTableCell>
                  <StyledTableCell>Is Featured</StyledTableCell>
                  <StyledTableCell>Is Banner Price</StyledTableCell>
                  <StyledTableCell>Added By</StyledTableCell>
                  <StyledTableCell>Added On</StyledTableCell>
                  <StyledTableCell>Last Modified</StyledTableCell>
                  <StyledTableCell>Content Event Mapping</StyledTableCell>
                  <StyledTableCell>Plan Identifier</StyledTableCell>
                  <StyledTableCell>About Plan</StyledTableCell>
                  <StyledTableCell>Display</StyledTableCell>
                  <StyledTableCell>Free Trial Days</StyledTableCell>
                  <StyledTableCell>Plan Title</StyledTableCell>
                  <StyledTableCell>Plan Benefits</StyledTableCell>
                  <StyledTableCell>Show Ads</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoading rows={13} columns={10} />
                ) : (
                  tableData.map((plan) => (
                    <TableRow hover key={plan.PLAN_DETAILS_ID} style={{ padding: 0 }}>
                      <TableCell>
                        <Stack direction="col" spacing={2}>
                          <IconButton
                            color="default"
                            aria-label="edit"
                            onClick={(e) => {
                              if (plan?.PLAN_DETAILS_ID) {
                                navigate(`/plan/details/edit/${plan?.PLAN_DETAILS_ID}`);
                              }
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton color="error" aria-label="del" onClick={(e) => {}}>
                            <Delete />
                          </IconButton>
                        </Stack>
                      </TableCell>

                      <TableCell>{plan.PLAN_DETAILS_ID}</TableCell>
                      <TableCell>{plan.PLAN_ID}</TableCell>
                      <StyledTableCell>{plan.PRODUCT_ID}</StyledTableCell>
                      <StyledTableCell>{plan.PLATFORM_ID}</StyledTableCell>
                      <StyledTableCell>{plan.PAYMENT_ID}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_CAPTION}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_COUNTRY}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_CURRENCY}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_CURRENCY_SYMBOL}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_PRICE}</StyledTableCell>
                      <StyledTableCell>{plan.ORIGINAL_PRICE}</StyledTableCell>
                      <StyledTableCell>{plan.RENEWAL_PRICE}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_TYPE}</StyledTableCell>
                      <StyledTableCell>{plan.IS_AUTO_RENEWAL}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_DURATION}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_DOWNLOADS}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_DETAILS}</StyledTableCell>
                      <StyledTableCell>
                        <StyledChip
                          label={plan.STATUS === 1 ? 'Active' : 'In-Active'}
                          color={plan.STATUS === 1 ? 'success' : 'default'}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{plan.STORE_PAYMENT_ID}</StyledTableCell>
                      <StyledTableCell>{plan.MULTI_PRICE}</StyledTableCell>
                      <StyledTableCell>{plan.COINS}</StyledTableCell>
                      <StyledTableCell>{plan.OFFER_TEXT}</StyledTableCell>
                      <StyledTableCell>{plan.OFFER_TEXT_DETAILS}</StyledTableCell>
                      <StyledTableCell>{plan.IS_FEATURED}</StyledTableCell>
                      <StyledTableCell>{plan.IS_BANNER_PRICE}</StyledTableCell>
                      <StyledTableCell>{plan.ADDED_BY}</StyledTableCell>
                      <StyledTableCell>
                        {moment(plan.ADDED_ON).format('MMMM Do YYYY, h:mm:ss a')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(plan.LAST_MODIFIED).format('MMMM Do YYYY, h:mm:ss a')}
                      </StyledTableCell>
                      <StyledTableCell>{plan.CONTENT_EVENT_MAPPING}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_IDENTIFIER}</StyledTableCell>
                      <StyledTableCell>{plan.ABOUT_PLAN}</StyledTableCell>
                      <StyledTableCell>{plan.DISPLAY}</StyledTableCell>
                      <StyledTableCell>{plan.FREE_TRIAL_DAYS}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_TITLE}</StyledTableCell>
                      <StyledTableCell>{plan.PLAN_BENEFITS}</StyledTableCell>
                      <StyledTableCell>{plan.SHOW_ADS}</StyledTableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          count={pagination?.total_pages ? pagination?.total_pages : 0}
          onChange={handleChange}
          page={pagination?.current_page ? pagination?.current_page : 0}
          color="primary"
          className={classes.pagination}
          showFirstButton
          showLastButton
          sx={{
            display: 'flex',
            m: 2,
            justifyContent: 'right'
          }}
        />
      </Card>
    </div>
  );
};

PlanList.propTypes = {
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  pageNumber: PropTypes.func,
  dataLimit: PropTypes.func,
  pagination: PropTypes.object
};

export default PlanList;
