import React from 'react';
import ManageRightsForm from 'src/components/adminUser/manageRights/ManageRightsForm';

const ManageRights = () => (
  <div>
    <ManageRightsForm />
  </div>
);

export default ManageRights;
