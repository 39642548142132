import VendorReportForm from 'src/components/report/vendor/VendorReportForm';
import { Helmet } from 'react-helmet';

const VendorReport = () => (
  <>
    <Helmet>
      <title> Vendor Report </title>
    </Helmet>
    <div>
      <VendorReportForm />
    </div>
  </>
);

export default VendorReport;
