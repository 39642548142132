/* eslint-disable*/
const statusCheck = (status, endDate) => {
  // console.log('status:', status, 'endDate:', endDate, typeof endDate, typeof status);
  // console.log('1st endDate', endDate);
  // endDate = Math.floor(new Date(endDate).getTime() / 1000);
  // console.log(Math.floor(new Date(endDate).getTime() / 1000), 'endateTime');
  // console.log(
  //   'TimeNow',
  //   Math.floor(Date.now() / 1000),
  //   'endDateTime',
  //   new Date(endDate).getTime() / 1000,
  //   'satus',
  //   Math.floor(new Date(endDate).getTime()) < Math.floor(Date.now() / 1000)
  // );
  if (new Date(endDate).getTime() / 1000 < Math.floor(Date.now() / 1000)) {
    status = 3;
  }
  // console.log('statusChange:', status);
  // console.log('Math.floor(Date.now() / 1000::', Math.floor(Date.now() / 1000));
  // console.log('endDate', endDate);
  // console.log(status, 'status');

  //old
  // if (status === 0) return { text: 'De-Active', color: 'error' };
  // if (status === 1) return { text: 'Active', color: 'success' };
  // if (status === 5) return { text: 'Pending', color: 'warning' };
  // if (status === 3) return { text: 'De-Active', color: 'error' };

  //new
  if (status === 0) return { text: 'Pending', color: 'warning' };
  if (status === 1) return { text: 'Active', color: 'success' };
  if (status === 5) return { text: 'De-Active', color: 'error' };
  if (status === 3) return { text: 'De-Active', color: 'error' };
  return { text: 'Unknown', color: 'default' };
};

const statusEnableDisable = (status) => {
  if (status === 0) return { text: 'In-Active', color: 'default' };
  if (status === 1) return { text: 'Active', color: 'success' };
  return { text: 'Unknown', color: 'default' };
};

module.exports = { statusCheck, statusEnableDisable };
