/* eslint-disable */
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import NestedRoutes from './sidebar/NestedRoutes';
import Route from './sidebar/Route';

const NavItem = ({ href, icon: Icon, title, nestedRoutes, ...rest }) => {
  const location = useLocation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false
        },
        location.pathname
      )
    : false;

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
      >
        {nestedRoutes && nestedRoutes.length !== 0 ? (
          <NestedRoutes title={title} nestedRoutes={nestedRoutes} active={active} />
        ) : (
          <Route href={href} title={title} />
        )}
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  nestedRoutes: PropTypes.array
};

export default NavItem;
