/* eslint-disable camelcase */
import axios from 'axios';

export function login(username, password, product_id, product_name) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/auth/admin/login`, {
      username,
      password,
      product_id,
      product_name,
    })
    .then((response) => {
      console.log('in auth', response.data.data);
      if (response.data.data.accessToken) {
        // localStorage.setItem('user_id', JSON.stringify(response.data.data.user_id));
        // localStorage.setItem('username', JSON.stringify(response.data.data.username));
        // localStorage.setItem('user_type', JSON.stringify(response.data.data.user_type));
        // localStorage.setItem('accessToken', JSON.stringify(response.data.data.accessToken));
        // localStorage.setItem('full_name', JSON.stringify(response.data.data.full_name));
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }
      return response.data;
    });
}

// export function logoutNew() {
export const logoutNew = async () => {
  // localStorage.removeItem('user');
  const logoutData = localStorage.removeItem('user');
  if (logoutData) {
    return { logoutData: true };
  }
  return { logoutData: true };
};

export function register(username, password, full_name, user_type) {
  // username, password, full_name, user_type
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/admin/register`, {
    username, password, full_name, user_type
  }).then((response) => {
    if (response.data.data) {
      return response.data;
    }
    return response.data;
  });
}
